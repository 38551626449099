.email-item {
    width: 115px;
    display: flex;
    padding-bottom: 10px;

    position: relative;
}

.email-item__image {
    width: 32px;
    height: 32px;
    border-radius: 15px;
}

.email-item__info {
    padding-left: 5px;

    display: flex;
    flex-direction: column;
    justify-content: center;
}

.email-item__name {
    margin: 0;
    font-size: 10px;
    font-weight: 400;
}
.email-item__email {
    margin: 0;
    font-size: 8px;
    font-weight: 400;

    color: #828282;
}

.email-item__delete {
    position: absolute;
    border: none;
    background: none;
    outline: none;
    width: 16px;
    height: 16px;
    color: white;
    border-radius: 8px;

    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;

    transform: rotate(45deg);

    text-align: center;

    top: 0px;
    right: 0px;

    display: none;
}

.email-item:hover .email-item__delete {
    display: block;
}
