.password-confirmation {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    font-family: "Montserrat";
}

.password-confirmation__container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.password-confirmation__content {
    margin-bottom: 14%;
    width: 380px;
    height: 200px;
    align-self: center;
    border: 1px solid #f47920;
    border-radius: 5px;
}

.password-confirmation__title {
    margin-top: 60px;
    margin-left: 10%;
    width: 300px;
    font-size: 20px;
    font-weight: 400;
    color: #5a5a5a;
    text-align: center;
}

.password-confirmation__button {
    margin-left: 26%;
    margin-top: 20px;
    margin-bottom: 7px;

    width: 180px;
    height: 22px;
    font-size: 12px;
    font-weight: 600;
    color: white;
    background-color: #1f9992;
    border: none;
    border-radius: 20px 20px 20px 20px / 20px 20px 20px 20px;
}

.password-confirmation__button:hover {
    cursor: pointer;
}

.password-confirmation__copyright {
    padding-top: 5px;
    padding-bottom: 5px;
    align-self: flex-end;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 48px;
    font-size: 10px;
    color: white;
    background-color: #1d2934;
}

@media (min-height: 1080px) {
    .password-confirmation__content {
        margin-bottom: 15%;
    }
}
