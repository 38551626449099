.share-report-form {
    width: 415px;

    border-radius: 10px;
    overflow: hidden;
    /* height: 230px; */

    /* padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 28px;
    padding-right: 28px; */
}

.share-report-form__emails {
    background-color: #fff;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 28px;
    padding-right: 28px;
}

.share-report-form__emails__text {
    font-size: 14px;
}

.share-report-form__emails__title,
.share-report-form__share__title {
    display: flex;
    align-items: center;

    padding-bottom: 10px;
}

.share-report-form__emails__title__text,
.share-report-form__share__title__text {
    font-size: 12px;
    font-weight: 500;
    padding-left: 5px;
}

.share-report-form__email__input-container {
    display: flex;
}

.share-report-form__email__input {
    display: block;
    border: none;
    background: none;
    outline: none;

    width: 100%;
    padding: 3px;

    font-size: 10px;

    border-bottom: 1px solid #828282;
}

.share-report-form__email__input.error,
.share-report-form__email__input.error:focus {
    border-bottom: 1px solid var(--color-negative-alert);
}

.share-report-form__email__input:focus {
    border-bottom: 1px solid #1c9690;
}

.share-report-form__email__input-button {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;

    /* background: #ddd; */
    width: 20px;
    height: 20px;
    padding: 0;
    border-radius: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.share-report-form__email__input-button:hover {
    background: #ddd;
}

.share-report-form__email__error {
    font-size: 8px;
    color: var(--color-negative-alert);
}

.share-report-form__email__list {
    padding-top: 15px;
    display: flex;

    flex-wrap: wrap;
}

.share-report-form__share {
    background-color: #e3e3e3;

    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 28px;
    padding-right: 28px;
}

.share-report-form__share__title__soon {
    padding-left: 5px;
    font-size: 8px;
    align-self: flex-end;
}

.share-report-form__share__checkbox {
    display: flex;
    padding-left: 6px;
}

.share-report-form__share__checkbox__text {
    padding-left: 6px;
    font-size: 10px;
}

.share-report-form__share__checkbox__button {
    padding-top: 12px;
    display: flex;
    justify-content: flex-end;
}
