.navbar__options {
    width: 100%;
    padding-left: 29px;
    padding-right: 15px;
}

.navbar__option__item {
    width: 100%;
    height: 55px;
    padding-top: 18px;
    padding-bottom: 18px;
    border-top: 1px solid #e5e6e8;

    font-size: 14px;
    font-family: Roboto;
    color: #1d2934;

    display: flex;
    flex-direction: column;
    justify-content: center;

    opacity: 0;
    animation: option-item-enters 0.4s ease-out forwards;
    animation-delay: 0.15s;
}

.navbar__options *:link,
.navbar__options *:visited {
    font-style: none;
    text-decoration: inherit;
}

@keyframes option-item-enters {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
