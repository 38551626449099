.alert-select {
    margin-left: 10px;
    margin-right: 10px;

    font-family: "Roboto";
    font-size: 12px;
    font-weight: 400;
    color: #505050;

    background-color: #e9ebef;
}

.hidden {
    display: none;
}

.alert-select__control-container {
    padding: 3px 10px 3px 10px;

    /* Set this to different options later */

    min-width: 16px;
    min-height: 10px;

    display: flex;
    align-items: center;
    border: 1px solid rgba(130, 130, 130, 0.5);
    cursor: pointer;
    background-color: white;
    border-radius: 5px;
    color: #2c2c2c;
}

.select-disabled {
    background-color: #e9ebef;
    cursor: default;
}

.alert-select__control-arrow {
    transform: rotate(90deg);
    margin-left: 10px;

    color: #505050;
}

.alert-select__control-arrow.rotate {
    transform: rotate(-90deg);
}

.alert-select__options-dropdown {
    position: absolute;
    max-height: 188px;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: #939393 transparent;
    z-index: 9999;
}

.alert-select__option {
    padding: 3px 10px 3px 10px;
    min-width: 28px;
    min-height: 10px;
    background-color: #e9ebef;
    border-bottom: 1px solid rgba(130, 130, 130, 0.5);
    cursor: pointer;
}

.alert-select__option:hover {
    background-color: #D2D5DB;
}

