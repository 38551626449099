.report-list {
    width: 100%;
    height: 100%;
    padding-top: 20px;
    padding-left: 20px;
    overflow-y: auto;
}

.report-list.collapsed {
    width: 345px;
    padding-left: 20px;
    padding-right: 20px;
    direction: rtl;
}
