.label-form {
    width: 100%;
}

.label-form__text {
    padding-top: 7px;
}

.label-form__input-container {
    display: flex;

    padding-top: 10px;
    margin-bottom: 8px;

    border-bottom: 1px solid #505050;
}

.label-form__input-container:focus-within {
    border-bottom: 1px solid var(--color-climate-green);
}

/* TODO this class config is being used  mora than 3 times, change it for a component or a generic class */
.label-form__input-container__button {
    width: 24px;
    height: 24px;
    border-radius: 13px;
    padding: 3px;

    border: none;
    background: none;
    outline: none;

    display: flex;
    align-items: center;

    cursor: pointer;

    transition: all 0.2s ease-out;
}

.label-form__input-container__button:hover {
    background-color: #ddd;
}

.label-form__input-container__input {
    display: block;
    width: 100%;

    font-family: Montserrat;
    font-size: 12px;

    border: none;
    background: none;
    outline: none;
}

.label-form__color-picker {
    padding-top: 10px;
}

.label-form__save {
    margin-top: 10px;

    border: none;
    outline: none;
    background: none;
    cursor: pointer;

    font-size: 14px;

    color: var(--color-climate-green);
}

.label-form__save:disabled {
    color: #ddd;
}
