.d3-line-area-chart-container {
    width: calc(100%);
}


@media screen and (max-width: 450px) {
    .d3-tip td div {
        width: 35px !important;
    }
}
