.crop-table {
    width: "100%";

    display: flex;
    justify-content: center;
    align-items: center;

    padding-bottom: 40px;
}

.crop-table__container {
    width: 100%;
}

.crop-table__icon-button {
    width: 19px;
    height: 19px;
    border-radius: 13px;

    border: none;
    background: none;
    outline: none;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;

    transition: all 0.2s ease-out;
}

.crop-table-check {
    width: 100%;

    display: flex;
    justify-content: flex-start;
    align-items: center;

    padding-left: 20px;
}

.crop-table__toggler-button {
    /* margin-bottom: 3px !important; */
    width: 12px;
    height: 12px;
    margin-left: 15px !important;
    border: none;
    background: none;
    outline: none;

    cursor: pointer;
}

.crop-table__toggler-icon {
    width: 5px;
    height: 10px;
}

.crop-table__toggler-icon--open {
    width: 5px;
    height: 10px;
    transform: rotate(90deg);
}

.crop-table__icon-button:hover {
    background-color: #ddd;
}

.crop-table__edit-icon {
    width: 22px;
    height: 22px;

    padding: 2px;
}

.crop-table__trash-icon {
    height: 22px;
    width: 22px;

    padding: 2px;
}

.crop-table__check-icon {
    height: 18px;
    width: 18px;

    padding: 1px;
}

.crop-table__alert-icon {
    height: 22px;
    width: 22px;
}

.crop-table__warning-icon {
    height: 18px;
    width: 18px;
}

.green-pct {
    color: green;
}

.red-pct {
    color: red;
}

td.check,
th.check,
td.actions,
th.actions,
td.labels,
th.labels,
td.shapefile,
th.shapefile {
    display: none !important;
}

.table-item__popover-target {
    z-index: 1310;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
}

.table-item__popover-message {
    padding: 5px 8px;

    max-width: 200px;
    height: max-content;

    font-family: "Roboto";
    font-size: 14px !important;
    font-weight: 400;
    text-align: center;
    color: white;

    border-radius: 10px;
    background: #333;
}

@media screen and (min-width: 1000px) {
    td.check,
    th.check,
    td.actions,
    th.actions,
    td.labels,
    th.labels,
    td.shapefile,
    th.shapefile {
        display: flex !important;
    }
}


