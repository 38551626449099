.table__draggable-headers {
    width: 100%;
    height: 40px;

    background-color: #c9d3dc;
    display: flex;
    align-items: center;

    padding-left: 60px;
    padding-right: 60px;
}

.table__draggable-headers__text {
    font-family: "Roboto";
    font-size: 14px;
    color: #1d2934;
}

.draggable-header {
    height: 28px;
    padding-left: 20px;
    border-radius: 14px;
    margin-left: 20px;

    background-color: var(--color-over-menu-bar);

    font-size: 14px;
    font-weight: bold;

    display: flex;
    justify-content: center;
    align-items: center;
}

.draggable-header__button {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    border: none;
    outline: none;
    margin-left: 14px;
    margin-right: 4px;
    padding: 5px;
    cursor: pointer;

    background-color: var(--color-climate-green);

    font-size: 10px;
    color: #fff;
}
/* 
@media screen and (min-width: 1440px) {
    .table__draggable-headers__text {
        font-size: 13px;
    }

    .table__draggable-headers {
        height: 35px;
    }
}

@media screen and (min-width: 1920px) {
    .table__draggable-headers__text {
        font-size: 14px;
    }

    .table__draggable-headers {
        height: 40px;
    }
}

@media screen and (min-width: 2300px) {
    .table__draggable-headers__text {
        font-size: 18px;
    }

    .table__draggable-headers {
        height: 60px;
    }
} */
