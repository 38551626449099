.toggle-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.toggle-label {
    font-family: "Roboto";
    font-size: 10px;
    font-weight: 400;
    color: rgba(80, 80, 80, 0.7);
}

.toggle-switch {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 18px;
}

.toggle-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* .toggle-switch input:hover {
  cursor: pointer;
} */

.toggle-round {
    position: absolute;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #939393;
    border-radius: 34px;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    cursor: pointer;
}

.disabled .toggle-round {
    cursor: auto;
}

.toggle-round:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 1px;
    bottom: 1px;
    background-color: white;
    border-radius: 50%;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked + .toggle-round {
    background: linear-gradient(180deg, #22c11f 0%, #03b800 100%);
}

input:checked + .toggle-round:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
}
