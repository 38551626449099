.select {
    position: relative;

    height: 36px;
    padding: 7px 8px;

    background: none;
    border: none;
    outline: none;

    border: 1px solid #e6e7e8;
    border-radius: 8px;
    background-color: #fff;

    display: flex;
    justify-content: center;
    align-items: center;
}

.select__icon {
    width: 24px;
    height: 24px;
    margin-right: 5px;
}

.select__value {
    display: block;
    flex: 1;

    font-size: 14px;
    font-family: Roboto;
    word-wrap: normal;
    color: #1a232f;

    white-space: nowrap;
    overflow: hidden;
}

.select__input {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    opacity: 0;
    z-index: 10;
}

.select__arrow {
    transform: rotate(270deg);
    margin-left: 10px;
}
