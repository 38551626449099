.planting-tool-v2 {
    position: relative;
    width: 100%;

    font-family: Montserrat;
    font-size: 14px;

    display: flex;
    column-gap: 20px;
}

.planting-tool-v2 * {
    box-sizing: border-box;
}

.planting-tool-v2__main {
    /* width: 800px; */
    overflow-x: hidden;
    flex: 1;
}

.planting-tool-v2__main__table,
.planting-tool-v2__main__graph {
    width: 100%;
}

.planting-tool-v2__main__graph {
    background-color: #fff;
    margin-bottom: 20px;
    /* padding: 20px; */
    border-radius: 10px;
    border: 1px solid #e5e5e5;
}

.planting-tool-v2__aside {
    flex: 1;
    max-width: 350px;
    display: flex;
    flex-direction: column;
}

.plating-tool__field-properties {
    display: block;
}

@media screen and (max-width: 1000px) {
    .planting-tool-v2 {
        flex-direction: column;
    }

    .planting-tool-v2__aside {
        width: 100%;
        max-width: unset;
        flex-direction: column-reverse;
    }

    .plating-tool__field-properties {
        display: none;
    }
}
