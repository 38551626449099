.chart-label-item__button {
    background: none;
    border: none;
    border-right: 1px solid #e5e5e5;
    padding: 5px;
    padding-left: 0;
    padding-right: 10px;

    display: flex;
    align-items: center;
    column-gap: 8px;

    /* cursor: pointer; */
}

.chart-label-item__button:last-of-type {
    border-right: none;
}

.chart-label-item__button.non-visible {
    opacity: 0.3;
    text-decoration: line-through;
}
