.search-input {
    height: 30px;
    width: 100%;

    border: 1px solid #e6e7e8;
    border-radius: 8px;
    padding-left: 10px;
    padding-right: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.search-input__icon {
    width: 20px;
    margin-right: 5px;
    height: inherit;
}

.search-input__input {
    width: 100%;
    display: block;
    height: inherit;

    border: none;
    outline: none;
    background: none;

    font-size: 14px;

    flex: 1;
}

.search-input__button {
    width: 21px;
    height: 21px;
    padding: 2px;
    border-radius: 20px;

    border: none;
    background: none;
    outline: none;

    display: flex;
    justify-content: center;
    align-items: center;

    color: var(--color-climate-green);

    cursor: pointer;

    transition: all 0.2s ease-out;
}

.search-input__button:hover {
    background-color: #ddd;
}
