.alert {
    box-sizing: border-box;

    width: 100%;
    height: 100%;

    padding-top: 10px;
    padding-bottom: 10px;

    font-family: "Montserrat";
}

.hidden {
    display: none;
}

.opacity {
    opacity: 50%;
}

.alert__container {
    padding-top: 20px;
    padding-bottom: 20px;

    width: 100%;
    height: max-content;

    border: 1px solid rgba(202, 205, 208, 0.5);
    border-radius: 10px;
    position: relative;
}

.alert__header {
    display: flex;
    align-items: center;

    height: 20px;
}

.alert__header__plus-icon {
    position: absolute;
    right: 10px;
    top: 20px;

    height: 24px;
    width: 24px;
    /* margin-left: 15px; */
    cursor: pointer;
}

.disabled {
    cursor: auto;
    opacity: 50%;
}

.alert__header__title {
    /* margin-right: auto; */
    font-size: 14px;
    font-weight: 600;
    color: #1d2934;
}

.alert__header__custom-icon {
    left: 22px;
    top: 5px;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 50px;
    height: 50px;
    padding: 10px;

    opacity: 0.5;

    margin-left: 10px;
}

.alert__header__alert-count {
    /* margin-right: auto; */
    margin-left: 10px;
    font-size: 14px;
}

.alert__header__arrow-icon {
    transform: rotate(90deg);

    margin-left: auto;
    margin-right: 50px;

    height: 15px;
    width: 15px;
}

.alert__header__arrow-icon.upside {
    transform: rotate(-90deg);
}

.alert__header__arrow-icon:hover {
    cursor: pointer;
}

.alert__content {
    padding: 10px;
}

.alert__content__header {
    margin-left: 30px;
    position: relative;
    display: flex;
    align-items: center;
}

.alert__content__title-dot {
    height: 15px;

    text-align: center;
    line-height: 0.3;
    font-size: 38px;
    font-weight: 600;
    color: #1d2934;
}

.alert__content__title-input {
    width: 50%;

    font-size: 12px;
    font-weight: 600;
    color: #1d2934;
}

.alert__content__title-input::placeholder {
    color: lightgray;
}

.alert__content__title-input:first-line {
    display: inline-block;
}

.alert__content__title {
    margin-left: 15px;

    width: 60%;

    font-size: 12px;
    font-weight: 600;
    color: #1d2934;
}

.alert__content__title-error {
    font-size: 12px;
    color: red;
    position: absolute;
    top: 24px;
    left: 14px;
}

.alert__content__slider-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.alert__content__slider-label {
    font-family: "Roboto";
    font-size: 10px;
    font-weight: 400;
    color: rgba(80, 80, 80, 0.7);
}

.alert__content__slider-switch {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 18px;
}

.alert__content__slider-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* .alert__content__slider-switch input:hover {
    cursor: pointer;
} */

.alert__content__slider-round {
    position: absolute;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #939393;
    border-radius: 34px;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    cursor: pointer;
}

.disabled .alert__content__slider-round {
    cursor: auto;
}

.alert__content__slider-round:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 1px;
    bottom: 1px;
    background-color: white;
    border-radius: 50%;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked + .alert__content__slider-round {
    background: linear-gradient(180deg, #22c11f 0%, #03b800 100%);
}

input:checked + .alert__content__slider-round:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
}

.alert__content__conditions {
    padding: 20px;
    padding-left: 40px;
}

[class^="alert__content__conditions__options-"] {
    margin-bottom: 5px;

    display: flex;

    font-family: "Roboto";
    font-size: 13px;
    color: #505050;
}

[class^="alert__content__conditions__options-"] strong {
    color: #1d2934;
}

.alert__content__conditions__options-s-margin,
.alert__content__conditions__options-m-margin {
    padding-top: 10px;
    display: flex;
    flex-flow: row wrap;
}

.alert__content__conditions__options-m-margin {
    margin-left: 20px;
}

.alert__content__conditions__options-l-margin {
    margin-top: 25px;
    margin-left: 40px;
}

.alert__content__conditions__container {
    max-width: 75px;
}

.alert__content__conditions__input {
    margin-right: 8px;
    margin-left: 8px;

    width: 50px;

    text-align: center;
    font-size: 12px;
    font-weight: 700;
    color: #2c2c2c;

    background-color: white;
    border: 1px solid rgba(130, 130, 130, 0.5);
    border-radius: 5px;
}

.alert__content__conditions__input::placeholder {
    color: lightgray;
}

.alert__content__conditions__input:disabled {
    background-color: #e9ebef;
}

.alert__content__conditions__input .alert__content__conditions__input:disabled {
    color: #2c2c2c;
    background-color: #e2e2e2;
}

.alert__content__conditions__input-error {
    color: red;
    margin-left: -100%;
    min-width: 400%;
    font-family: "Montserrat";
    font-size: 12px;
}

.alert__content__edit-button-container {
    display: flex;
    justify-content: flex-end;
    gap: 20px;

    margin-right: 30%;
}

.alert__content__edit-icon {
    height: 22px;
    width: 22px;
}

.alert__content__edit-icon:hover {
    cursor: pointer;
}

.alert__content__trash-icon {
    height: 22px;
    width: 22px;
}

.alert__content__trash-icon:hover {
    cursor: pointer;
}

.alert__content__button-container {
    display: flex;
    justify-content: flex-end;
    gap: 20px;

    margin-right: 20px;
}

.alert__content__cancel-button {
    width: 90px;
    height: 22px;
    font-family: "Montserrat";
    font-size: 12px;
    font-weight: 500;
    color: white;
    background-color: #f47920;
    border: none;
    border-radius: 20px 20px 20px 20px / 20px 20px 20px 20px;
}

.alert__content__cancel-button:hover {
    cursor: pointer;
}

.alert__content__save-button {
    width: 90px;
    height: 22px;
    font-family: "Montserrat";
    font-size: 12px;
    font-weight: 500;
    color: white;
    background-color: #1f9992;
    border: none;
    border-radius: 20px 20px 20px 20px / 20px 20px 20px 20px;
}

.alert__content__save-button:hover {
    cursor: pointer;
}

.alert__content__divider {
    height: 20px;
}

.alert__content__divider::after {
    content: "";
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    border-bottom: 1px solid rgba(29, 41, 52, 0.5);
}

.alert__header__loading-bar {
    position: absolute;
    top: 1px;
    width: 96%;
    left: 2%;
}

@media screen and (max-width: 600px) {
    .alert__container {
        border-radius: 0;
    }

    .alert__content__header {
        flex-direction: column;
        align-items: flex-start;
        row-gap: 5px;
    }

    .alert__content__title {
        margin: 0;
    }

    .alert__content__title-input {
        width: 100%;
    }

    .alert__header__arrow-icon {
        position: absolute;
        top: 10px;
        right: 10px;
        margin: 0;
        display: block;
    }

    .toggle-container {
        display: flex;
        flex-direction: row;
        column-gap: 10px;
    }

    .alert__content__edit-button-container {
        margin-right: 10px;
    }
}
