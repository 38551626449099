.change-password-modal {
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;

    font-family: "Montserrat";

    background-color: rgba(0, 0, 0, 0.5);
}

.change-password-modal__container {
    position: relative;
    bottom: 100px;

    display: flex;
    flex-direction: column;

    width: max-content;
    height: max-content;

    border-radius: 10px;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
}

.change-password-modal__content-white {
    padding-top: 25px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 30px;

    min-height: 50px;

    width: 400px;

    background-color: #fff;

    border-radius: inherit;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.change-password-modal__content-gray {
    padding-top: 20px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 5px;

    min-height: 30px;

    width: 400px;

    background-color: #e3e3e3;

    border-radius: inherit;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.change-password-modal__title {
    margin-left: auto;
    margin-right: auto;

    width: 350px;

    text-align: center;
    line-height: 2;
    font-size: 12px;
    font-weight: 500;
    color: rgba(29, 41, 52, 0.85);
}

.change-password-modal__input {
    padding-top: 30px;
    padding-left: 10px;

    width: 95%;

    font-family: "Roboto";
    line-height: 1.3;
    font-size: 10px;
    font-weight: 400;
    color: rgba(29, 41, 52, 0.7);

    background-color: transparent;
    border: 0;
    border-bottom: 2px solid rgba(28, 150, 144, 0.6);
}

.change-password-modal__input::placeholder {
    color: rgba(80, 80, 80, 0.8);
}

.change-password-modal__button-container {
    margin-right: 10px;

    display: flex;
    justify-content: flex-end;
}

.change-password-modal__cancel-button {
    margin-right: 20px;

    width: 100px;
    height: 15px;

    line-height: 1.7;
    font-size: 10.5px;
    font-weight: 400;
    color: white;

    background-color: #f47920;
    border: none;
    border-radius: 20px 20px 20px 20px / 20px 20px 20px 20px;
}

.change-password-modal__cancel-button:hover {
    cursor: pointer;
}

.change-password-modal__accept-button {
    width: 100px;
    height: 15px;

    line-height: 1.7;
    font-size: 10.5px;
    font-weight: 400;
    color: white;

    background-color: #1c9690;
    border: none;
    border-radius: 20px 20px 20px 20px / 20px 20px 20px 20px;
}

.change-password-modal__accept-button:hover {
    cursor: pointer;
}
