.scenario-card {
    width: 100%;
    background-color: #fff;
    border: 1px solid #e5e6e8;
    border-radius: 10px;
    margin-bottom: 20px;
}

.scenario-card__header {
    font-size: 16px;
    display: flex;
    align-items: center;

    flex: 1;
    font-weight: 600;
    padding-left: 25px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 20px;

    border-bottom: 1px solid #e5e6e8;
}
