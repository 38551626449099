.scenario-item {
    width: 100%;
    position: relative;

    padding-left: 25px;
    padding-top: 15px;
    padding-right: 20px;
}

.scenario-item__main {
    display: flex;
    padding-bottom: 15px;
}

.scenario-item__content {
    padding-left: 20px;
}

.scenario-item__name {
    outline: none;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0 !important;

    font-size: 16px;
    display: flex;
    align-items: center;
}

.scenario-item__name__text {
    margin: 0;
    transition: all ease-out 0.2s;
}

.scenario-item__name__edit {
    position: absolute;

    width: 15px;
    height: 15px;

    opacity: 0;
    transition: all ease-out 0.2s;
}

.scenario-item__name:hover > .scenario-item__name__text {
    transform: translateX(20px);
}
.scenario-item__name.not-editable:hover > .scenario-item__name__text {
    transform: initial;
    cursor: auto;
}
.scenario-item__name:hover > .scenario-item__name__edit {
    opacity: 1;
}

.scenario-item__risk__alert-name {
    font-family: Roboto;
    font-size: 14px;
    color: #a4a4a4;
    display: flex;
    align-items: flex-end;
}

.scenario-item__risk__alert-name__threshold-label {
    padding-left: 5px;
    font-size: 12px;
    min-width: 80px;
}

.scenario-item__description {
    font-size: 14px;
    color: #666d74;

    padding-top: 8px;
    display: flex;
}

.scenario-item__toggle {
    outline: none;
    background: none;
    border: none;
    cursor: pointer;
    border-radius: 12px;

    position: absolute;
    width: 24px;
    height: 24px;
    padding: 5px;
    right: 20px;

    transition: all 0.2s ease-out;
}

.scenario-item__toggle:hover {
    background: #e6e6e6;
}

.scenario-item__toggle.open {
    transform: rotateZ(180deg);
}

.scenario-item__risks__item {
    display: flex;

    padding-top: 15px;
    padding-bottom: 15px;
}

.scenario-item__risk__item__icon {
    width: 24px;
    height: 24px;
    opacity: 0.5;
}

.scenario-item__risk__content {
    padding-left: 20px;
}

.scenario-item__risks__placeholder {
    padding: 10px 0px;
    text-align: left;
    color: #a4a4a4;
}
