.chart-specs {
    margin-left: 3px;
    display: flex;
    flex-direction: column;

    height: 100%;
    width: 100%;

    font-size: 12px;
    font-weight: 600;

    background-color: white;
}

.chart-specs__slider-block {
    width: 100%;
}

.chart-specs__specs-block-container {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 40px;

    height: 100%;
    width: 100%;
}

.bar-legend-stats {
    margin-top: 20px;

    display: flex;
    flex-direction: column;
    gap: 20px;
}

.bar-legend-stats__block {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
}

.chart-specs__specs-block {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}

.chart-specs__i-icon-container {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
}

.chart-specs__i-icon {
    width: 18px;
    margin-left: 12px;
}

.chart-specs__slider-block h1,
.chart-specs__specs-block h1 {
    font-family: "Montserrat";
    font-size: 18px;
    font-weight: 600;
    color: #1d2934;
}

.chart-specs__toggle-container {
    display: flex;
    justify-content: start;
}

.chart-specs__toggle-container-text {
    width: 110px;
}

@media screen and (max-width: 1100px) {
    .chart-specs {
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .chart-specs__specs-block-container {
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .chart-specs__specs-block {
        flex-basis: 50%;
        max-width: 160px;
    }

    .chart-specs__specs-block.toggler {
        flex-basis: 25%;
        max-width: unset;
    }
}

@media screen and (max-width: 750px) {
    .chart-specs__specs-block {
        flex-basis: 40%;
        max-width: unset;
    }

    .chart-specs__specs-block.toggler {
        flex-basis: 40%;
        max-width: unset;
    }
}

@media screen and (max-width: 650px) {
    .chart-specs__specs-block {
        flex-basis: 46%;
        max-width: unset;
    }

    .chart-specs__specs-block.toggler {
        flex-basis: 100%;
        max-width: unset;
    }
}

@media screen and (max-width: 500px) {
    .chart-specs__specs-block {
        margin: 0 auto 0 5%;
        flex-basis: 100%;
        max-width: unset;
    }

    .chart-specs__specs-block {
        margin: 0 auto 0 5%;
        flex-basis: 100%;
    }
}
