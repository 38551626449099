.locale {
    font-family: "Montserrat";
    font-size: 12px;
    color: white;
}

.locale-text {
    text-decoration: none;
    color: inherit;
}

@media screen and (max-width: 1000px) {
    .locale {
        font-size: 16px;
    }
}
