@keyframes opacityOnAndOff {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.message {
    animation: opacityOnAndOff 2.5s infinite;
}
