.scenarios-actions {
    display: flex;
    flex-grow: 1;

    align-items: center;
    justify-content: center;
}

.scenarios-actions__button {
    background: none;
    border: none;

    width: 30px;
    height: 30px;
    padding: 2px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    justify-self: flex-end;

    cursor: pointer;
}

.scenarios-actions__button.right {
    margin-left: auto;
}

.scenarios-actions__button.left {
    margin-right: auto;
}

.scenarios__advance-settings-separator {
    border-top: 1px solid #e5e6e8;
}
