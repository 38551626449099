.confirmation-modal {
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;

    font-family: "Montserrat";

    background-color: rgba(0, 0, 0, 0.5);
}

.confirmation-modal__container {
    position: relative;
    bottom: 100px;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 35px;
    padding-right: 35px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: max-content;
    height: max-content;

    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
}

.confirmation-modal__title {
    font-size: 12px;
    font-weight: 600;
    color: #1d2934;
}

.confirmation-modal__text {
    margin-top: 15px;

    width: 350px;

    text-align: center;
    font-size: 12px;
    font-weight: 500;
    color: #1d2934;
}

.confirmation-modal__continue-button {
    margin-top: 15px;

    width: 100px;
    height: 16px;

    line-height: 1.7;
    font-size: 10.5px;
    font-weight: 400;
    color: white;

    background-color: #1c9690;
    border: none;
    border-radius: 20px 20px 20px 20px / 20px 20px 20px 20px;
}

.confirmation-modal__continue-button:disabled {
    background-color: #a7b0afe3;
    cursor: not-allowed !important;
}

.confirmation-modal__continue-button:hover {
    cursor: pointer;
}
