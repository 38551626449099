.simple-modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.5);

    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;
}

.simple-modal__content {
    min-width: 200px;
    min-height: 100px;

    position: relative;

    background-color: #fff;
    border-radius: 10px;
}

.simple-modal__content__title {
    position: absolute;
    background-color: #fff;

    font-size: 15px;
    font-weight: 700;

    width: 100%;
    height: 50px;
    border-radius: 10px;
    margin: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    bottom: calc(100% + 10px);
}
