@tailwind base;

@layer base {
    html {
        line-height: 1;
    }

    svg {
        display: inline;
        vertical-align: unset;
    }
}

@tailwind components;
@tailwind utilities;

@layer utilities {
    .montserrat {
        font-family: "Montserrat";
    }

    .roboto {
        font-family: "Roboto";
    }

    .elevation-1 {
        box-shadow: 0px 2px 4px rgba(26, 35, 47, 0.1);
    }

    .elevation-2 {
        box-shadow: 0px 4px 8px rgba(29, 41, 52, 0.1);
    }
}

.climateai-select {
    @apply appearance-none bg-transparent border border border-gray-10 rounded-lg px-2 roboto text-[16px] text-gray-60 placeholder:text-gray-30 disabled:bg-gray-3 disabled:cursor-not-allowed cursor-pointer pr-5 truncate;
    background-image: url("data:image/svg+xml;utf8,<svg fill='gray' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 98%;
    background-position-y: 5px;
}

.climateai-date-input {
    @apply relative appearance-none bg-transparent border border border-gray-10 rounded-lg px-2 roboto text-[16px] text-gray-60 placeholder:text-gray-30 disabled:bg-gray-3 disabled:cursor-not-allowed cursor-pointer pr-5 truncate;
}

.climateai-date-input::-webkit-calendar-picker-indicator {
    @apply absolute inset-y-auto right-0 w-[24px] h-[24px] pr-[7px] mt-[2px] cursor-pointer;
    background-image: url("data:image/svg+xml;utf8,<svg width='100%' height='100%' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M17 12H12V17H17V12ZM16 1V3H8V1H6V3H5C3.89 3 3.01 3.9 3.01 5L3 19C3 20.1 3.89 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3H18V1H16ZM19 19H5V8H19V19Z' fill='gray'/></svg>");
}

html,
body,
#root {
    width: 100%;
    height: 100%;
    overflow-y: auto;
}

body {
    margin: 0;

    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&family=Roboto:wght@400;500;700&display=swap");
@font-face {
    font-family: "Montserrat-Bold";
    src: url("./assets/fonts/Montserrat-Bold.ttf");
}

@font-face {
    font-family: "Montserrat";
    src: url("./assets/fonts/Montserrat-Regular.ttf");
}

:root {
    /* shadows */
    --shadow-generic: 0px 4px 10px #c0d2e4;
    --shadow-positive: 0px 4px 10px rgba(116, 167, 49, 0.44);
    --shadow-medium: 0px 4px 10px rgba(255, 154, 61, 0.44);
    --shadow-negative: 0px 4px 10px rgba(255, 61, 61, 0.44);

    /* colors */
    --color-inactive-icon: #8a9198;
    --color-active-icon: #384e63;
    --color-normal-status: #74a731;
    --color-negative-alert: #ff3d3d;
    --color-medium-alert: #ffbd3d;
    --color-positive-alert: #22c11f;
    --color-climate-green: #1f9992;
    --color-general-grey: #404040;
    --color-orange-climate: #f27930;
    --color-over-menu-bar: #aae0df;
    --color-dark-blue: #1d2934;
    --color-red-graph-growing: #da3849;
    --color-yellow-graph-growing: #e5a339;
    --color-yellow-wind: #faaa11;
    --color-blue-precipitation: #2288c1;

    /* gradients */
}

/* text */
.title-section-text {
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 12px;
}

.card-title {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 12px;
}

.title {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 18px;
}

.regular-text,
.standard-text-card {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 12px;
}

.table-text {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 10px;
}

.table-title {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-size: 12px;
}

.side-menu-title {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 16px;
}

.side-menu-date-text {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 14px;
}

.side-menu-text {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 14px;
}

.line {
    fill: none;
    stroke: steelblue;
    stroke-width: 2px;
}

.area {
    fill: lightsteelblue;
}

header.mui-fixed {
    z-index: 9998 !important;
}

html {
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    height: 100%;
}

#root {
    height: 100%;
}

input[type=file], /* FF, IE7+, chrome (except button) */
input[type=file]::-webkit-file-upload-button {
    /* chromes and blink button */
    cursor: pointer;
}

/* STYLE INJECTIONS TO FIX THE CALENDAR OVERLAPPING */

.MuiPickersBasePicker-container {
    /* z-index: 9999; */
    background-color: white;
    border: 1px solid rgba(202, 205, 208, 0.5);
    border-radius: 10px;
}

/* .MuiPickersBasePicker-pickerView {
    z-index: 9999;
}

.MuiPickersCalendar-transitionContainer {
    z-index: 9999;
} */
