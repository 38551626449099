/* TableHeader Component */
.table-header {
    z-index: 1500;
}

.table-header .table-header__icon {
    z-index: 1500;
    position: absolute;
    bottom: -10px;

    width: 20px;
    height: 20px;
    padding: 3px;
    border-radius: 10px;

    background: #fff;
    box-shadow: 0px 1px 2px 0 #000000dd;

    font-size: 16px;
    color: #505050;

    display: flex;
    justify-content: center;
    align-items: center;

    opacity: 0;
}

.table-header.active {
    color: #333;
}

.table-header:hover .table-header__icon {
    opacity: 1;
}

.table-header.active .table-header__icon {
    opacity: 1;
}
