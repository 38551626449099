.sign-up {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    font-family: "Montserrat";
}

.sign-up__container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.sign-up__content {
    margin-bottom: 10%;
    width: 491px;
    height: 378px;
    align-self: center;
    border: 1px solid #f47920;
    border-radius: 5px;
}

.sign-up__title {
    margin-top: 50px;
    margin-left: 18%;
    width: 315px;
    font-size: 20px;
    font-weight: 400;
    color: #5a5a5a;
    text-align: center;
}

.sign-up__checkbox-container {
    position: relative;
    display: flex;
    flex-direction: row;
}

.sign-up__checkbox-container {
    position: relative;
    margin-top: 9px;
    margin-left: 19%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: max-content;
    max-height: 11.5px;
}

.sign-up__checkbox {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
    cursor: pointer;
}

.sign-up__checkmark {
    margin-top: 4px;
    position: absolute;
    width: 9px;
    height: 9px;
    background-color: white;
    border: 1px solid #505050;
}

.sign-up__checkmark::after {
    content: "";
    position: absolute;
    display: none;
}

.sign-up__checkmark--checked {
    margin-top: 4px;
    position: absolute;
    width: 9px;
    height: 9px;
    background-color: #1d2934;
    border: 1px solid #505050;
}

.sign-up__checkmark--checked::after {
    content: "";
    position: absolute;
    left: 2px;
    bottom: 0.5px;
    display: block;
    width: 3.5px;
    height: 9px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.sign-up__checkbox-text {
    position: absolute;
    margin-left: 15px;
    margin-top: 1px;
    width: max-content;
    font-size: 14px;
    font-weight: 600;
    color: #505050;
}

.sign-up__form {
    margin-top: 20px;
    margin-left: 25%;
}

.sign-up__text-input {
    margin-top: 20px;
    padding-left: 12px;
    padding-bottom: 2px;
    width: 65%;
    font-size: 12px;
    line-height: 1.6;
    color: #505050;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 5px;
}

.sign-up__button-container {
    display: flex;
    flex-direction: row;
    gap: 7%;
}

.sign-up__cancel-button {
    margin-top: 30px;
    margin-bottom: 7px;

    width: 115px;
    height: 22px;
    font-size: 12px;
    font-weight: 600;
    color: white;
    background-color: #f47920;
    border: none;
    border-radius: 20px 20px 20px 20px / 20px 20px 20px 20px;
}

.sign-up__cancel-button:hover {
    cursor: pointer;
}

.sign-up__confirm-button {
    margin-top: 30px;
    margin-bottom: 7px;

    width: 115px;
    height: 22px;
    font-size: 12px;
    font-weight: 600;
    color: white;
    background-color: #7e8382a8;
    border: none;
    border-radius: 20px 20px 20px 20px / 20px 20px 20px 20px;

    cursor: not-allowed !important;
}

.sign-up__confirm-button.enabled {
    background-color: #1f9992;
    cursor: pointer !important;
}

.sign-up__confirm-button:hover {
    cursor: pointer;
}

.sign-up__copyright {
    padding-top: 5px;
    padding-bottom: 5px;
    align-self: flex-end;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 48px;
    font-size: 10px;
    color: white;
    background-color: #1d2934;
}

@media (min-height: 1080px) {
    .sign-up__content {
        margin-bottom: 15%;
    }
}
