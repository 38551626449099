.report-list-item {
    display: inline-block;
    margin-right: 20px;
    margin-bottom: 20px;
    width: 280px;
    position: relative;
    direction: initial;
}

.report-list-item__container {
    flex: 1;
}

.report-list.collapsed .report-list-item__container {
    padding-left: 10px;
}

.report-list.collapsed .report-list-item {
    width: 100%;
    margin-right: 0;
    display: flex;
}

.report-list-item.drag-right::after {
    content: "";
    position: absolute;
    display: block;
    width: 4px;
    height: 180px;
    background-color: #ee6900;
    right: -12px;
    border-radius: 2px;
    top: 0;
    opacity: 0.5;
}

.report-list-item.drag-left::after {
    content: "";
    position: absolute;
    display: block;
    width: 4px;
    height: 180px;
    background-color: #ee6900;
    left: -12px;
    border-radius: 2px;
    top: 0;
    opacity: 0.5;
}

.report-list.collapsed .report-list-item.drag-right::after {
    right: -8px;
}

.report-list.collapsed .report-list-item.drag-left::after {
    left: -8px;
}

.report-list-item__number-left {
    display: none;
}

.report-list-item__number-left.active {
    color: #ee6900;
}

.report-list-item__number-bottom {
    display: block;
    padding-right: 10px;
}

.report-list-item__image-container {
    width: 100%;
    height: 180px;

    margin-bottom: 13px;
    background: #fff;

    border: none;
    margin: none;
    padding: none;

    border: 1px solid #e5e5e5;
    border-radius: 10px;
    overflow: hidden;
    display: block;
    cursor: grab;

    overflow: hidden;
    position: relative;
    z-index: 10;

    transition: transform 0.2s ease-out;
}

.report-list-item__image {
    width: inherit;
    height: auto;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 1;
}

.report-list-item.active .report-list-item__image-container {
    border: 1px solid #f37920;
    /* transform: scale(0.98); */
}

.report-list-item__text {
    font-family: Roboto;
    font-weight: 400;
    font-size: 14px;
}

.report-list-item__title {
    display: flex;
    /* flex-direction: row-reverse; */
    /* align-items: center; */
    width: 100%;
    min-height: 50px;
}

.report-list-item__title__input,
.report-list-item__title__button {
    border: none;
    background: none;
    outline: none;
    font-size: inherit;
    font-family: inherit;
    color: inherit;
    display: block;
    padding: 0;
    width: 100%;
    text-align: left;
    white-space: normal;
    word-wrap: break-word;
    cursor: text;
}

.report-list-item__title__input {
    border: 1px solid #e5e5e5;
    background: #fff;
    border-radius: 10px;
    /* font-size: 14px; */
    height: initial;
    padding: 5px;
}

.report-list-item__loader {
    width: 100%;
    z-index: 2;
    position: absolute;
    bottom: 0;
}

.report-list.collapsed .report-list-item__number-left {
    display: block;
}

.report-list.collapsed .report-list-item__number-bottom {
    display: none;
}
