@-webkit-keyframes mdc-checkbox-unchecked-checked-checkmark-path {
  0%,
  50% {
    stroke-dashoffset: 29.78334; }
  50% {
    -webkit-animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
            animation-timing-function: cubic-bezier(0, 0, 0.2, 1); }
  100% {
    stroke-dashoffset: 0; } }

@keyframes mdc-checkbox-unchecked-checked-checkmark-path {
  0%,
  50% {
    stroke-dashoffset: 29.78334; }
  50% {
    -webkit-animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
            animation-timing-function: cubic-bezier(0, 0, 0.2, 1); }
  100% {
    stroke-dashoffset: 0; } }

@-webkit-keyframes mdc-checkbox-unchecked-indeterminate-mixedmark {
  0%,
  68.2% {
    -webkit-transform: scaleX(0);
            transform: scaleX(0); }
  68.2% {
    -webkit-animation-timing-function: cubic-bezier(0, 0, 0, 1);
            animation-timing-function: cubic-bezier(0, 0, 0, 1); }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1); } }

@keyframes mdc-checkbox-unchecked-indeterminate-mixedmark {
  0%,
  68.2% {
    -webkit-transform: scaleX(0);
            transform: scaleX(0); }
  68.2% {
    -webkit-animation-timing-function: cubic-bezier(0, 0, 0, 1);
            animation-timing-function: cubic-bezier(0, 0, 0, 1); }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1); } }

@-webkit-keyframes mdc-checkbox-checked-unchecked-checkmark-path {
  from {
    -webkit-animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
            animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
    opacity: 1;
    stroke-dashoffset: 0; }
  to {
    opacity: 0;
    stroke-dashoffset: -29.78334; } }

@keyframes mdc-checkbox-checked-unchecked-checkmark-path {
  from {
    -webkit-animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
            animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
    opacity: 1;
    stroke-dashoffset: 0; }
  to {
    opacity: 0;
    stroke-dashoffset: -29.78334; } }

@-webkit-keyframes mdc-checkbox-checked-indeterminate-checkmark {
  from {
    -webkit-animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
            animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    opacity: 1; }
  to {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    opacity: 0; } }

@keyframes mdc-checkbox-checked-indeterminate-checkmark {
  from {
    -webkit-animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
            animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    opacity: 1; }
  to {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    opacity: 0; } }

@-webkit-keyframes mdc-checkbox-indeterminate-checked-checkmark {
  from {
    -webkit-animation-timing-function: cubic-bezier(0.14, 0, 0, 1);
            animation-timing-function: cubic-bezier(0.14, 0, 0, 1);
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    opacity: 0; }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    opacity: 1; } }

@keyframes mdc-checkbox-indeterminate-checked-checkmark {
  from {
    -webkit-animation-timing-function: cubic-bezier(0.14, 0, 0, 1);
            animation-timing-function: cubic-bezier(0.14, 0, 0, 1);
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    opacity: 0; }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    opacity: 1; } }

@-webkit-keyframes mdc-checkbox-checked-indeterminate-mixedmark {
  from {
    -webkit-animation-timing-function: mdc-animation-deceleration-curve-timing-function;
            animation-timing-function: mdc-animation-deceleration-curve-timing-function;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    opacity: 0; }
  to {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    opacity: 1; } }

@keyframes mdc-checkbox-checked-indeterminate-mixedmark {
  from {
    -webkit-animation-timing-function: mdc-animation-deceleration-curve-timing-function;
            animation-timing-function: mdc-animation-deceleration-curve-timing-function;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    opacity: 0; }
  to {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    opacity: 1; } }

@-webkit-keyframes mdc-checkbox-indeterminate-checked-mixedmark {
  from {
    -webkit-animation-timing-function: cubic-bezier(0.14, 0, 0, 1);
            animation-timing-function: cubic-bezier(0.14, 0, 0, 1);
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    opacity: 1; }
  to {
    -webkit-transform: rotate(315deg);
            transform: rotate(315deg);
    opacity: 0; } }

@keyframes mdc-checkbox-indeterminate-checked-mixedmark {
  from {
    -webkit-animation-timing-function: cubic-bezier(0.14, 0, 0, 1);
            animation-timing-function: cubic-bezier(0.14, 0, 0, 1);
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    opacity: 1; }
  to {
    -webkit-transform: rotate(315deg);
            transform: rotate(315deg);
    opacity: 0; } }

@-webkit-keyframes mdc-checkbox-indeterminate-unchecked-mixedmark {
  0% {
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    opacity: 1; }
  32.8%,
  100% {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    opacity: 0; } }

@keyframes mdc-checkbox-indeterminate-unchecked-mixedmark {
  0% {
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    opacity: 1; }
  32.8%,
  100% {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    opacity: 0; } }

.mdc-checkbox {
  display: inline-block;
  position: relative;
  -webkit-box-flex: 0;
          flex: 0 0 18px;
  box-sizing: content-box;
  width: 18px;
  height: 18px;
  padding: 11px;
  line-height: 0;
  white-space: nowrap;
  cursor: pointer;
  vertical-align: bottom; }

.mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate) ~ .mdc-checkbox__background {
  border-color: rgba(0, 0, 0, 0.54);
  background-color: transparent; }

.mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
.mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background {
  border-color: #018786;
  /* @alternate */
  border-color: var(--mdc-theme-secondary, #018786);
  background-color: #018786;
  /* @alternate */
  background-color: var(--mdc-theme-secondary, #018786); }

@-webkit-keyframes mdc-checkbox-fade-in-background-0 {
  0% {
    border-color: rgba(0, 0, 0, 0.54);
    background-color: transparent; }
  50% {
    border-color: #018786;
    /* @alternate */
    border-color: var(--mdc-theme-secondary, #018786);
    background-color: #018786;
    /* @alternate */
    background-color: var(--mdc-theme-secondary, #018786); } }

@keyframes mdc-checkbox-fade-in-background-0 {
  0% {
    border-color: rgba(0, 0, 0, 0.54);
    background-color: transparent; }
  50% {
    border-color: #018786;
    /* @alternate */
    border-color: var(--mdc-theme-secondary, #018786);
    background-color: #018786;
    /* @alternate */
    background-color: var(--mdc-theme-secondary, #018786); } }

@-webkit-keyframes mdc-checkbox-fade-out-background-0 {
  0%,
  80% {
    border-color: #018786;
    /* @alternate */
    border-color: var(--mdc-theme-secondary, #018786);
    background-color: #018786;
    /* @alternate */
    background-color: var(--mdc-theme-secondary, #018786); }
  100% {
    border-color: rgba(0, 0, 0, 0.54);
    background-color: transparent; } }

@keyframes mdc-checkbox-fade-out-background-0 {
  0%,
  80% {
    border-color: #018786;
    /* @alternate */
    border-color: var(--mdc-theme-secondary, #018786);
    background-color: #018786;
    /* @alternate */
    background-color: var(--mdc-theme-secondary, #018786); }
  100% {
    border-color: rgba(0, 0, 0, 0.54);
    background-color: transparent; } }

.mdc-checkbox--anim-unchecked-checked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background, .mdc-checkbox--anim-unchecked-indeterminate .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background {
  -webkit-animation-name: mdc-checkbox-fade-in-background-0;
          animation-name: mdc-checkbox-fade-in-background-0; }

.mdc-checkbox--anim-checked-unchecked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background, .mdc-checkbox--anim-indeterminate-unchecked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background {
  -webkit-animation-name: mdc-checkbox-fade-out-background-0;
          animation-name: mdc-checkbox-fade-out-background-0; }

.mdc-checkbox__checkmark {
  color: #fff; }

.mdc-checkbox__mixedmark {
  border-color: #fff; }

.mdc-checkbox__background::before {
  background-color: #018786; }
  @supports not (-ms-ime-align: auto) {
    .mdc-checkbox__background::before {
      /* @alternate */
      background-color: var(--mdc-theme-secondary, #018786); } }

.mdc-checkbox__native-control[disabled]:not(:checked):not(:indeterminate) ~ .mdc-checkbox__background {
  border-color: rgba(0, 0, 0, 0.26); }

.mdc-checkbox__native-control[disabled]:checked ~ .mdc-checkbox__background,
.mdc-checkbox__native-control[disabled]:indeterminate ~ .mdc-checkbox__background {
  border-color: transparent;
  background-color: rgba(0, 0, 0, 0.26); }

@media screen and (-ms-high-contrast: active) {
  .mdc-checkbox__mixedmark {
    margin: 0 1px; } }

.mdc-checkbox--disabled {
  cursor: default;
  pointer-events: none; }

.mdc-checkbox__background {
  /* @noflip */
  left: 11px;
  /* @noflip */
  right: initial;
  display: -webkit-inline-box;
  display: inline-flex;
  position: absolute;
  top: 11px;
  bottom: 0;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  box-sizing: border-box;
  width: 45%;
  height: 45%;
  border: 2px solid currentColor;
  border-radius: 2px;
  background-color: transparent;
  pointer-events: none;
  will-change: background-color, border-color;
  -webkit-transition: background-color 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), border-color 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  transition: background-color 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), border-color 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1); }
  .mdc-checkbox[dir="rtl"] .mdc-checkbox__background,
  [dir="rtl"] .mdc-checkbox .mdc-checkbox__background {
    /* @noflip */
    left: initial;
    /* @noflip */
    right: 11px; }

.mdc-checkbox__checkmark {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  -webkit-transition: opacity 180ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  transition: opacity 180ms 0ms cubic-bezier(0.4, 0, 0.6, 1); }
  .mdc-checkbox--upgraded .mdc-checkbox__checkmark {
    opacity: 1; }

.mdc-checkbox__checkmark-path {
  -webkit-transition: stroke-dashoffset 180ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  transition: stroke-dashoffset 180ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  stroke: currentColor;
  stroke-width: 3.12px;
  stroke-dashoffset: 29.78334;
  stroke-dasharray: 29.78334; }

.mdc-checkbox__mixedmark {
  width: 100%;
  height: 0;
  -webkit-transform: scaleX(0) rotate(0deg);
          transform: scaleX(0) rotate(0deg);
  border-width: 1px;
  border-style: solid;
  opacity: 0;
  -webkit-transition: opacity 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), -webkit-transform 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  transition: opacity 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), -webkit-transform 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  transition: opacity 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), transform 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  transition: opacity 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), transform 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), -webkit-transform 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1); }

.mdc-checkbox--upgraded .mdc-checkbox__background,
.mdc-checkbox--upgraded .mdc-checkbox__checkmark,
.mdc-checkbox--upgraded .mdc-checkbox__checkmark-path,
.mdc-checkbox--upgraded .mdc-checkbox__mixedmark {
  -webkit-transition: none !important;
  transition: none !important; }

.mdc-checkbox--anim-unchecked-checked .mdc-checkbox__background, .mdc-checkbox--anim-unchecked-indeterminate .mdc-checkbox__background, .mdc-checkbox--anim-checked-unchecked .mdc-checkbox__background, .mdc-checkbox--anim-indeterminate-unchecked .mdc-checkbox__background {
  -webkit-animation-duration: 180ms;
          animation-duration: 180ms;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear; }

.mdc-checkbox--anim-unchecked-checked .mdc-checkbox__checkmark-path {
  -webkit-animation: mdc-checkbox-unchecked-checked-checkmark-path 180ms linear 0s;
          animation: mdc-checkbox-unchecked-checked-checkmark-path 180ms linear 0s;
  -webkit-transition: none;
  transition: none; }

.mdc-checkbox--anim-unchecked-indeterminate .mdc-checkbox__mixedmark {
  -webkit-animation: mdc-checkbox-unchecked-indeterminate-mixedmark 90ms linear 0s;
          animation: mdc-checkbox-unchecked-indeterminate-mixedmark 90ms linear 0s;
  -webkit-transition: none;
  transition: none; }

.mdc-checkbox--anim-checked-unchecked .mdc-checkbox__checkmark-path {
  -webkit-animation: mdc-checkbox-checked-unchecked-checkmark-path 90ms linear 0s;
          animation: mdc-checkbox-checked-unchecked-checkmark-path 90ms linear 0s;
  -webkit-transition: none;
  transition: none; }

.mdc-checkbox--anim-checked-indeterminate .mdc-checkbox__checkmark {
  -webkit-animation: mdc-checkbox-checked-indeterminate-checkmark 90ms linear 0s;
          animation: mdc-checkbox-checked-indeterminate-checkmark 90ms linear 0s;
  -webkit-transition: none;
  transition: none; }

.mdc-checkbox--anim-checked-indeterminate .mdc-checkbox__mixedmark {
  -webkit-animation: mdc-checkbox-checked-indeterminate-mixedmark 90ms linear 0s;
          animation: mdc-checkbox-checked-indeterminate-mixedmark 90ms linear 0s;
  -webkit-transition: none;
  transition: none; }

.mdc-checkbox--anim-indeterminate-checked .mdc-checkbox__checkmark {
  -webkit-animation: mdc-checkbox-indeterminate-checked-checkmark 500ms linear 0s;
          animation: mdc-checkbox-indeterminate-checked-checkmark 500ms linear 0s;
  -webkit-transition: none;
  transition: none; }

.mdc-checkbox--anim-indeterminate-checked .mdc-checkbox__mixedmark {
  -webkit-animation: mdc-checkbox-indeterminate-checked-mixedmark 500ms linear 0s;
          animation: mdc-checkbox-indeterminate-checked-mixedmark 500ms linear 0s;
  -webkit-transition: none;
  transition: none; }

.mdc-checkbox--anim-indeterminate-unchecked .mdc-checkbox__mixedmark {
  -webkit-animation: mdc-checkbox-indeterminate-unchecked-mixedmark 300ms linear 0s;
          animation: mdc-checkbox-indeterminate-unchecked-mixedmark 300ms linear 0s;
  -webkit-transition: none;
  transition: none; }

.mdc-checkbox__native-control:checked ~ .mdc-checkbox__background,
.mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background {
  -webkit-transition: border-color 90ms 0ms cubic-bezier(0, 0, 0.2, 1), background-color 90ms 0ms cubic-bezier(0, 0, 0.2, 1);
  transition: border-color 90ms 0ms cubic-bezier(0, 0, 0.2, 1), background-color 90ms 0ms cubic-bezier(0, 0, 0.2, 1); }
  .mdc-checkbox__native-control:checked ~ .mdc-checkbox__background .mdc-checkbox__checkmark-path,
  .mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background .mdc-checkbox__checkmark-path {
    stroke-dashoffset: 0; }

.mdc-checkbox__background::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: scale(0, 0);
          transform: scale(0, 0);
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
  will-change: opacity, transform;
  -webkit-transition: opacity 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), -webkit-transform 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  transition: opacity 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), -webkit-transform 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  transition: opacity 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), transform 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  transition: opacity 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), transform 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), -webkit-transform 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1); }

.mdc-checkbox__native-control:focus ~ .mdc-checkbox__background::before {
  -webkit-transform: scale(2.75, 2.75);
          transform: scale(2.75, 2.75);
  opacity: 0.12;
  -webkit-transition: opacity 80ms 0ms cubic-bezier(0, 0, 0.2, 1), -webkit-transform 80ms 0ms cubic-bezier(0, 0, 0.2, 1);
  transition: opacity 80ms 0ms cubic-bezier(0, 0, 0.2, 1), -webkit-transform 80ms 0ms cubic-bezier(0, 0, 0.2, 1);
  transition: opacity 80ms 0ms cubic-bezier(0, 0, 0.2, 1), transform 80ms 0ms cubic-bezier(0, 0, 0.2, 1);
  transition: opacity 80ms 0ms cubic-bezier(0, 0, 0.2, 1), transform 80ms 0ms cubic-bezier(0, 0, 0.2, 1), -webkit-transform 80ms 0ms cubic-bezier(0, 0, 0.2, 1); }

.mdc-checkbox__native-control {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  opacity: 0;
  cursor: inherit; }
  .mdc-checkbox__native-control:disabled {
    cursor: default;
    pointer-events: none; }

.mdc-checkbox__native-control:checked ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
  -webkit-transition: opacity 180ms 0ms cubic-bezier(0, 0, 0.2, 1), -webkit-transform 180ms 0ms cubic-bezier(0, 0, 0.2, 1);
  transition: opacity 180ms 0ms cubic-bezier(0, 0, 0.2, 1), -webkit-transform 180ms 0ms cubic-bezier(0, 0, 0.2, 1);
  transition: opacity 180ms 0ms cubic-bezier(0, 0, 0.2, 1), transform 180ms 0ms cubic-bezier(0, 0, 0.2, 1);
  transition: opacity 180ms 0ms cubic-bezier(0, 0, 0.2, 1), transform 180ms 0ms cubic-bezier(0, 0, 0.2, 1), -webkit-transform 180ms 0ms cubic-bezier(0, 0, 0.2, 1);
  opacity: 1; }

.mdc-checkbox__native-control:checked ~ .mdc-checkbox__background .mdc-checkbox__mixedmark {
  -webkit-transform: scaleX(1) rotate(-45deg);
          transform: scaleX(1) rotate(-45deg); }

.mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  opacity: 0;
  -webkit-transition: opacity 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), -webkit-transform 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  transition: opacity 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), -webkit-transform 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  transition: opacity 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), transform 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  transition: opacity 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), transform 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), -webkit-transform 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1); }

.mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background .mdc-checkbox__mixedmark {
  -webkit-transform: scaleX(1) rotate(0deg);
          transform: scaleX(1) rotate(0deg);
  opacity: 1; }

@-webkit-keyframes mdc-ripple-fg-radius-in {
  from {
    -webkit-animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    -webkit-transform: translate(var(--mdc-ripple-fg-translate-start, 0)) scale(1);
            transform: translate(var(--mdc-ripple-fg-translate-start, 0)) scale(1); }
  to {
    -webkit-transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
            transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); } }

@keyframes mdc-ripple-fg-radius-in {
  from {
    -webkit-animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    -webkit-transform: translate(var(--mdc-ripple-fg-translate-start, 0)) scale(1);
            transform: translate(var(--mdc-ripple-fg-translate-start, 0)) scale(1); }
  to {
    -webkit-transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
            transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); } }

@-webkit-keyframes mdc-ripple-fg-opacity-in {
  from {
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    opacity: 0; }
  to {
    opacity: var(--mdc-ripple-fg-opacity, 0); } }

@keyframes mdc-ripple-fg-opacity-in {
  from {
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    opacity: 0; }
  to {
    opacity: var(--mdc-ripple-fg-opacity, 0); } }

@-webkit-keyframes mdc-ripple-fg-opacity-out {
  from {
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    opacity: var(--mdc-ripple-fg-opacity, 0); }
  to {
    opacity: 0; } }

@keyframes mdc-ripple-fg-opacity-out {
  from {
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    opacity: var(--mdc-ripple-fg-opacity, 0); }
  to {
    opacity: 0; } }

.mdc-ripple-surface--test-edge-var-bug {
  --mdc-ripple-surface-test-edge-var: 1px solid #000;
  visibility: hidden; }
  .mdc-ripple-surface--test-edge-var-bug::before {
    border: var(--mdc-ripple-surface-test-edge-var); }

.mdc-checkbox {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
  .mdc-checkbox::before, .mdc-checkbox::after {
    position: absolute;
    border-radius: 50%;
    opacity: 0;
    pointer-events: none;
    content: ""; }
  .mdc-checkbox::before {
    -webkit-transition: opacity 15ms linear, background-color 15ms linear;
    transition: opacity 15ms linear, background-color 15ms linear;
    z-index: 1; }
  .mdc-checkbox.mdc-ripple-upgraded::before {
    -webkit-transform: scale(var(--mdc-ripple-fg-scale, 1));
            transform: scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-checkbox.mdc-ripple-upgraded::after {
    top: 0;
    /* @noflip */
    left: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: center center;
            transform-origin: center center; }
  .mdc-checkbox.mdc-ripple-upgraded--unbounded::after {
    top: var(--mdc-ripple-top, 0);
    /* @noflip */
    left: var(--mdc-ripple-left, 0); }
  .mdc-checkbox.mdc-ripple-upgraded--foreground-activation::after {
    -webkit-animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
            animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards; }
  .mdc-checkbox.mdc-ripple-upgraded--foreground-deactivation::after {
    -webkit-animation: mdc-ripple-fg-opacity-out 150ms;
            animation: mdc-ripple-fg-opacity-out 150ms;
    -webkit-transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
            transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-checkbox::before, .mdc-checkbox::after {
    background-color: #018786; }
    @supports not (-ms-ime-align: auto) {
      .mdc-checkbox::before, .mdc-checkbox::after {
        /* @alternate */
        background-color: var(--mdc-theme-secondary, #018786); } }
  .mdc-checkbox:hover::before {
    opacity: 0.04; }
  .mdc-checkbox:not(.mdc-ripple-upgraded):focus::before, .mdc-checkbox.mdc-ripple-upgraded--background-focused::before {
    -webkit-transition-duration: 75ms;
            transition-duration: 75ms;
    opacity: 0.12; }
  .mdc-checkbox:not(.mdc-ripple-upgraded)::after {
    -webkit-transition: opacity 150ms linear;
    transition: opacity 150ms linear; }
  .mdc-checkbox:not(.mdc-ripple-upgraded):active::after {
    -webkit-transition-duration: 75ms;
            transition-duration: 75ms;
    opacity: 0.12; }
  .mdc-checkbox.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: 0.12; }
  .mdc-checkbox::before, .mdc-checkbox::after {
    top: calc(50% - 50%);
    /* @noflip */
    left: calc(50% - 50%);
    width: 100%;
    height: 100%; }
  .mdc-checkbox.mdc-ripple-upgraded::before, .mdc-checkbox.mdc-ripple-upgraded::after {
    top: var(--mdc-ripple-top, calc(50% - 50%));
    /* @noflip */
    left: var(--mdc-ripple-left, calc(50% - 50%));
    width: var(--mdc-ripple-fg-size, 100%);
    height: var(--mdc-ripple-fg-size, 100%); }
  .mdc-checkbox.mdc-ripple-upgraded::after {
    width: var(--mdc-ripple-fg-size, 100%);
    height: var(--mdc-ripple-fg-size, 100%); }

.mdc-ripple-upgraded--background-focused .mdc-checkbox__background::before {
  content: none; }
