.sign-up-confirmation {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    font-family: "Montserrat";
}

.sign-up-confirmation__container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.sign-up-confirmation__content {
    margin-bottom: 14%;
    width: 491px;
    height: 250px;
    align-self: center;
    border: 1px solid #f47920;
    border-radius: 5px;
}

.sign-up-confirmation__title-container {
    margin-top: 60px;
    margin-left: 10%;
}

.sign-up-confirmation__title {
    width: 400px;
    font-size: 20px;
    font-weight: 400;
    color: #5a5a5a;
    text-align: center;
}

.sign-up-confirmation__text {
    margin-left: 10%;
    margin-top: 15px;
    width: 400px;
    font-size: 12px;
    font-weight: 400;
    color: #5a5a5a;
    text-align: center;
}

.sign-up-confirmation__button {
    margin-left: 40%;
    margin-top: 30px;
    margin-bottom: 7px;

    width: 115px;
    height: 22px;
    font-size: 12px;
    font-weight: 600;
    color: white;
    background-color: #1f9992;
    border: none;
    border-radius: 20px 20px 20px 20px / 20px 20px 20px 20px;
}

.sign-up-confirmation__button:hover {
    cursor: pointer;
}

.sign-up-confirmation__copyright {
    padding-top: 5px;
    padding-bottom: 5px;
    align-self: flex-end;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 48px;
    font-size: 10px;
    color: white;
    background-color: #1d2934;
}

@media (min-height: 1080px) {
    .sign-up-confirmation__content {
        margin-bottom: 15%;
    }
}
