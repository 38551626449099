.dashboard * {
    box-sizing: border-box;
}

.dashboard {
    margin: 0;
    padding: 0;

    background: rgba(248, 251, 255, 1);

    min-height: 100%;
    width: 100%;
}

/* .dashboard__sidebar {
    display: flex;
    flex-direction: column;
    width: 200px;
    justify-content: space-between;
}

.dashboard__about {
    margin-bottom: 50px;
    margin-left: 40px;
    color: rgba(29, 41, 52, 0.5);
}

.dashboard__about:hover {
    cursor: pointer;
} */

.dashboard__header {
    width: 100%;

    padding: 20px;

    margin: 0 auto;
    position: relative;

    display: flex;
    justify-content: space-between;
}

.dashboard__logo {
    width: 120px;
}

.dashboard__logo > a {
    width: 100%;
    height: 100%;
}

.dashboard__header__title {
    margin: 0;
    padding-left: 40px;

    display: flex;
    align-items: center;

    font-size: 20px;
    font-weight: 600;
}

.dashboard__header__user-info {
    display: flex;
    align-items: flex-start;
}

.dashboard__header__user-info__text {
    padding-left: 8px;
    padding-right: 40px;
}

.dashboard__header__user-info__text__name {
    margin: 0;

    font-size: 10px;
    font-weight: 600;
}
.dashboard__header__user-info__text__logout {
    margin: 0;

    border: none;
    background: none;
    padding: 0;
    margin: 0;
    outline: none;
    cursor: pointer;

    font-size: 8px;
    font-weight: 400;
}

.dashboard__header__user-info__image {
    width: 32px;
    height: 32px;
    border-radius: 15px;
}

.dashboard__table {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 1300px;
    width: 100%;
}

/* Fixes the Field's Name column overlapping */
.dashboard .small-controls.down {
    z-index: 1550;
}

@media screen and (min-width: 1000px) {
    .dashboard {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .dashboard__table {
        width: 100%;
        max-width: 1400px;
    }
}
