.weather-model {
    padding: 0px 0 30px 0;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 20px;

    width: 100%;
    height: 100%;
}

/* CHART STYLES */

.weather-chart {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.weather-chart__chart-container {
    padding: 40px;
    margin-right: 15px;
    /* width: calc(100% - 124px); */
    flex-basis: 100%;
    min-width: 300px;
    width: 100%;
    max-width: 1500px;
    height: 100%;
    max-height: 700px;
    background: white;
    border: 1px solid rgba(202, 205, 208, 0.5);
    border-radius: 10px;
}

.weather-chart__chart-container .svg-chart-container {
    width: 100% !important;
}

.weather-chart__preload-container {
    width: 100%;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
}

.weather-chart__preload-container div {
    color: #1c9690 !important;
}

.weather-chart__specs-container {
    padding: 20px;
    flex-basis: 20%;
    min-width: 180px;
    width: 100%;
    max-width: 450px;
    background: white;
    border: 1px solid rgba(202, 205, 208, 0.5);
    border-radius: 10px;
    box-sizing: unset;
}

/* GDD Chart */
.weather-chart__gdd-cumulative-toggle {
    position: relative;
    z-index: 930;
    padding: 8px 5px;
    margin-top: -50px;
    top: 45px;
    float: right;

    background-color: white;
    border: 1px solid #41a3a4;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgb(56 78 99 / 40%);
}

@media screen and (max-width: 490px) {
    .weather-chart__gdd-cumulative-toggle {
        margin-left: 60px;
        float: left;
    }

    .weather-chart__chart-container.gdd .icon-title {
        margin-top: 70px !important;
    }
}

@media screen and (max-width: 1100px) {
    .weather-chart {
        flex-direction: column;
    }

    .weather-chart__chart-container {
        margin-right: 0;
        margin-bottom: 15px;
        padding-left: 10px;
        padding-right: 25px;
        max-width: 100%;
        box-sizing: border-box;
    }

    .weather-chart__specs-container {
        max-width: 100%;
        box-sizing: border-box;
    }
}

@media screen and (max-width: 750px) {
    .weather-chart__chart-container {
        padding-right: 15px;
    }
}

/* CHART ACTIONS */

.chart-actions {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.chart-actions__options-container {
    padding: 20px;
    display: flex;
    height: 80px;
    min-width: 700px;
    width: 100%;
    max-width: 1300px;
    background: white;
    border: 1px solid rgba(202, 205, 208, 0.5);
    border-radius: 10px;
    box-sizing: unset;
}

.chart-actions__actions-container {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
}

.chart-action__header-block-option {
    margin: 0 10px;
    width: 100% !important;
    position: relative;
}

.chart-actions__dropdown-button {
    position: relative;

    margin: 0 0.5em;
    width: 28px;
    border-radius: 5px;
    border: 1px solid #1c9690;

    display: inline-flex;
    align-items: center;
    justify-content: center;

    transition: 200ms;
}

.chart-actions__dropdown-button:hover {
    cursor: pointer;
    /* box-shadow: 0px 4px 10px #c0d2e4; */
    box-shadow: 0px 4px 10px #c4c4c4;
    /* box-shadow: 0px 4px 10px #1c9690; */
}

.chart-actions__dropdown-button.open {
    background-color: #1c9690;
}

/* .chart-actions__field-properties {
    padding: 20px;
    padding-left: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-around;
    height: 100px;
    box-sizing: border-box;
    background: white;
    border: 1px solid rgba(202, 205, 208, 0.5);
    border-radius: 10px;
} */

.chart-action--media {
    display: initial;
}

.chart-action__icon {
    height: 24px;
    width: 24px;
}

.weather-tabs__alert-dot {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: #ff3d3d;
}

.chart-actions__field-properties {
    flex-basis: 30%;
    min-width: 300px;
    width: 100%;
    max-width: 650px;
}

/* TODO: Work on the className of the elements of ChartActions */

@media screen and (max-width: 1150px) {
    .chart-actions {
        flex-wrap: wrap;
        max-width: 100%;
    }
    .chart-actions__options-container {
        /* display: flex; */
        /* justify-content: space-between; */
        display: flex;
        padding: 15px 20px;
        height: max-content;
        width: 100%;
    }

    .chart-actions__options-container {
        min-width: 300px;
    }

    .chart-actions__actions-container {
        padding: 0 20px;
        justify-content: space-between;
        width: 100%;
    }

    .chart-action__header-block-option {
        width: 130px !important;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 1em;
    }

    .chart-actions__field-properties .field-properties__info {
        font-size: 13px;
    }

    .chart-actions__field-properties .mapboxgl-map {
        margin-top: -80px !important;
        height: 100px !important;
        width: 135px !important;
    }

    .chart-actions__dropdown-button {
        margin: auto 15px autp 20px;
    }

    /* .chart-actions__field-properties .mapboxgl-canvas {
        height: 50px !important;
    } */
}

/* @media screen and (max-width: 800px) {
    .chart-actions__options-container {
        display: grid;
        grid-template-columns: minmax(150px, 1fr) minmax(200px, 580px) minmax(55px, 1fr);
    }

    
} */

@media screen and (max-width: 680px) {
    .chart-actions__options-container {
        display: grid;
        grid-template-columns: 90% 10%;
        grid-template-rows: 1fr 1fr;
    }

    .chart-actions__actions-container {
        padding: 0;
        gap: 20px;
    }
}

@media screen and (max-width: 550px) {
    .chart-actions__options-container {
        height: 100px;
        grid-template-rows: 1fr;
    }

    .chart-actions__actions-container {
        justify-content: space-evenly;
        gap: 10px;
    }
}

/* ACTION BLOCK */

.action-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 0 10px;
    height: 100%;
    /* min-width: fit-content; */
    flex-basis: 70%;
    min-width: 110px;
    width: 100%;
    max-width: 150px;
    /* flex-grow: 1; */
    border-radius: 5px;
    transition: 200ms;
    border: 1px solid #1c9690;
}

.action-block:hover {
    cursor: pointer;
    box-shadow: 0px 4px 10px #c4c4c4;
}

.chart-action__icon {
    margin-top: 10px;
}

.action-block__title {
    margin-top: 5px 10px 0px 10px;
    text-align: center;
    line-height: 12px;
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 12px;
    height: max-content;
}

@media screen and (max-width: 1100px) {
    .action-block {
        height: 100%;
        margin-bottom: 1em;
    }
}

@media screen and (max-width: 680px) {
    .action-block {
        margin: 0;
    }
}

/* DROPDOWN */

.tool__export-dropdown {
    display: block;
}

@media screen and (max-width: 1100px) {
    .tool__export-dropdown {
        display: none;
    }
}
