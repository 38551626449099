.toggler-button {
    position: relative;
}

.toggler-button__button {
    width: 32px;
    height: 32px;

    display: flex;
    justify-content: center;
    align-items: center;

    background: none;
    border: none;
    padding: 6px;
    border-radius: 16px;

    cursor: pointer;

    transition: all 0.15s ease-out;
}

.toggler-button__button:hover {
    background: #ededed;
}
