.change-picture-modal {
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;

    font-family: "Montserrat";

    background-color: rgba(0, 0, 0, 0.5);
}

.change-picture-modal__container {
    position: relative;
    bottom: 100px;
    padding-top: 35px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 30px;

    display: flex;
    flex-direction: column;
    align-items: center;

    width: 400px;
    height: max-content;

    background-color: #fff;

    border-radius: 10px;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
}

.change-picture-modal__title {
    font-size: 12px;
    font-weight: 500;
    color: rgba(29, 41, 52, 0.85);
}

.change-picture-modal__image {
    margin-top: 20px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 150px;
    height: 150px;

    text-transform: uppercase;
    font-size: 80px;
    font-weight: 600;
    color: white;

    background-color: var(--color-orange-climate);
    border-radius: 50%;
}

.change-picture-modal__browse-button {
    margin-top: 20px;

    width: 260px;
    height: 25px;

    line-height: 1.7;
    font-size: 13px;
    font-weight: 500;
    color: white;

    background-color: #525252;
    border: none;
    border-radius: 20px 20px 20px 20px / 20px 20px 20px 20px;
}

.change-picture-modal__button-container {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;

    width: 260px;
}
.change-picture-modal__delete-button {
    width: 80px;
    height: 15px;

    line-height: 1.7;
    font-size: 8.5px;
    font-weight: 400;
    color: #525252;

    background-color: transparent;
    border: none;
    border-radius: 20px 20px 20px 20px / 20px 20px 20px 20px;
}

.change-picture-modal__cancel-button {
    width: 80px;
    height: 15px;

    line-height: 1.7;
    font-size: 10.5px;
    font-weight: 400;
    color: white;

    background-color: #f47920;
    border: none;
    border-radius: 20px 20px 20px 20px / 20px 20px 20px 20px;
}

.change-picture-modal__confirm-button {
    width: 80px;
    height: 15px;

    line-height: 1.7;
    font-size: 10.5px;
    font-weight: 400;
    color: white;

    background-color: #1c9690;
    border: none;
    border-radius: 20px 20px 20px 20px / 20px 20px 20px 20px;
}
