.label-item {
    margin-top: 10px;

    color: #fff;

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.label-item__label,
.label-item__delete {
    outline: none;
    border: none;
    background: none;
}

button.label-item__label {
    position: relative;

    display: flex;

    align-items: center;
    justify-content: flex-start;

    flex: 1;
    margin-right: 10px;
    height: 28px;
    border-radius: 14px;
    padding-left: 10px;

    overflow: hidden;
    white-space: nowrap;

    font-size: 14px;
    color: #fff;

    cursor: pointer;
    transition: transform 0.2s ease-out;
}

.label-item__label__name {
    max-width: 100px;
    overflow: hidden;
}

.label-item__delete {
    width: 24px;
    height: 24px;

    border-radius: 12px;
    padding: 2px;

    border: none;
    background: none;
    outline: none;
    opacity: 0;
    pointer-events: none;

    display: flex;
    align-items: center;

    cursor: pointer;

    transition: all 0.2s ease-out;
}

/* This is going to change later */
.label-item__delete > div {
    width: 100%;
    height: 100%;
}

.label-item__delete:hover {
    background-color: #ddd;
}

.label-item:hover button.label-item__label {
    transform: translateX(5px);
}

.label-item:hover .label-item__delete {
    opacity: 1;
    pointer-events: all;
}
