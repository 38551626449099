.menu {
    z-index: 90;
    position: relative;
    display: flex;
    background: none;
    border: none;
    outline: none;

    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.menu__children {
    position: absolute;
    width: 400px;
    background-color: #fff;
    top: 100%;
    right: 0;

    border-radius: 8px;

    border: 1px solid #ddd;
}

.menu > .select {
    cursor: pointer;
}
