/*********** MAPBOX MAP OVERRIDES ***************/
/* 
.mapboxgl-ctrl-logo {
    display: none !important;
}

.mapboxgl-ctrl-attrib {
    display: none !important;
} */

/* .tip-table {
    line-height: 16px;
    margin: 0 auto;
}

.tip-table th:first-child {
    font-weight: 600;
    text-align: right;
} */
/* 
.mapboxgl-popup-content {
    pointer-events: none;
    border-radius: 15px !important;
    z-index: 999999;
    padding-left: 30px !important;
    padding-right: 30px !important;
    padding-bottom: 20px !important;
}

.mapboxgl-popup-content {
    padding: 17px 19px 15px;
} */

.mapboxgl-popup-close-button {
    display: none;
}

/* .mapboxgl-popup-tip {
    border: none;
    width: 30px !important;
    height: 30px !important;
    border-right-width: 25px;
    border-left-width: 25px;
    background-color: white;
    transform: rotate(45deg);
    top: 18px;
    border-radius: 5px;
}  */
/* 
.mapboxgl-popup-content {
    box-shadow: none;
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-content {
    margin-top: -20px;
}

.mapboxgl-popup-anchor-top-left .mapboxgl-popup-content {
    margin-top: -23px;
    margin-left: -7px;
}

.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-content {
    margin-top: -18px;
    margin-left: -9px;
}

.mapboxgl-popup-anchor-top-right .mapboxgl-popup-content {
    margin-top: -17px;
    margin-right: -10px;
}

.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-content {
    margin-top: -17px;
    margin-right: -10px;
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-content {
    margin-bottom: -20px;
}

.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-content {
    margin-bottom: -20px;
}

.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-content {
    margin-bottom: -20px;
} */

/*********** LAYER SWITCH BUTTON ***************/

.layer-switch {
    display: flex;
    justify-content: center;
    position: absolute;
    z-index: 20;
    align-items: center;
    min-height: 40px;
    min-width: 38px;
    cursor: pointer;
    background-color: #fafafa;
    pointer-events: all;
    padding: 7px;
    margin: 10px;
    border-radius: 10px;
}

.layer-switch:hover {
    background-color: #dedede;
}

