.planting-tool-v2__main__graph text {
    font-size: 14px;
}

.planting-tool-chart__header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 20px;
    padding-top: 12px;
    padding-bottom: 12px;
    row-gap: 20px;

    border-bottom: 1px solid #e5e5e5;
}
.planting-tool-chart__header .scenario-form__planting__day {
    width: 100px;
}

.planting-tool-chart__header .card-item__main {
    padding: 0;
}

.planting-tool-chart__header__container {
    display: flex;
    position: relative;
    align-items: center;
    column-gap: 10px;
    padding: 8px;
    border-radius: 8px;
}

.planting-tool-chart__header__container:hover {
    border: 1px solid #e5e5e5;
    padding: 7px;
}

.planting-tool-chart__header__container__info-icon {
    position: relative;
    width: 20px;
    height: 20px;
    margin-right: 15px;
}

.planting-tool-chart__header__title {
    position: relative;

    font-size: 16px;
    font-family: Montserrat;
    font-weight: 600;

    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.planting-tool-chart__header__subtitle {
    margin: 0;
    color: #666d74;
    font-size: 14px;
    font-weight: 400;
}

.planting-tool-chart__header__container select {
    position: absolute;
    width: 100%;
    height: 100%;

    font-size: inherit;
    font-family: inherit;
    font-weight: inherit;

    border: none;
    background: none;
    outline: none;
    cursor: pointer;

    opacity: 0;
}

.planting-tool-chart__range-date {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: 10px;

    color: #b3b6ba;

    font-family: Roboto;
}

.planting-tool-chart__range-date input[type="text"] {
    border: none;
    background: none;
    outline: none;
    cursor: pointer;
    border-radius: 10px;

    width: 100%;
    margin: 0px 5px;
    padding: 5px 0px;
}

.planting-tool-chart__range-date__input-container {
    display: flex;
    align-items: center;

    margin-top: 5px;
    padding: 5px;
    border: 1px solid #c4c4c4;
    border-radius: 8px;
}

.planting-tool-chart__range-date__input-container__icon {
    width: 20px;
    height: 100%;
}

.planting-tool-chart__header__icon {
    width: 12px;
    height: 12px;
    position: relative;
    top: -15px;
    right: 0;
}

.planting-tool-chart__loader-container {
    width: 100%;
    height: 100%;
    padding: 50px 0px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 20px;
}

/* Overwrite date picker */

.react-datepicker-popper {
    z-index: 9999;
}

.react-datepicker-wrapper {
    width: initial;
}

.react-datepicker__header {
    background-color: #fff;
    border-bottom: none;
}
