.create-password {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    font-family: "Montserrat";
}

.create-password__container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.create-password__content {
    margin-bottom: 10%;
    /* justify-content: center; */
    width: 380px;
    height: 340px;
    align-self: center;
    border: 1px solid #f47920;
    border-radius: 5px;
}

.create-password__title {
    margin-top: 35px;
    margin-left: 8%;
    width: 315px;
    font-size: 20px;
    font-weight: 400;
    color: #5a5a5a;
    text-align: center;
}

.create-password__text {
    margin-left: 18%;
    margin-top: 15px;
    width: 230px;
    font-size: 12px;
    font-weight: 400;
    color: #5a5a5a;
    text-align: center;
}

.create-password__form {
    margin-top: 20px;
    margin-left: 16%;
}

.create-password__form-text {
    margin-left: 25%;
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
}

.create-password__form-text-2 {
    margin-top: 10px;
    margin-left: 15%;
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
}

.create-password__text-input {
    margin-left: 2%;
    padding-bottom: 2px;
    width: 75%;
    font-size: 12px;
    line-height: 1.6;
    color: #505050;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 5px;
}

.create-password__button-container {
    display: flex;
    flex-direction: row;
    gap: 7%;
}

.create-password__cancel-button {
    margin-top: 30px;
    margin-bottom: 7px;

    width: 115px;
    height: 22px;
    font-size: 12px;
    font-weight: 600;
    color: white;
    background-color: #f47920;
    border: none;
    border-radius: 20px 20px 20px 20px / 20px 20px 20px 20px;
}

.create-password__cancel-button:hover {
    cursor: pointer;
}

.create-password__confirm-button {
    margin-top: 30px;
    margin-bottom: 7px;

    width: 115px;
    height: 22px;
    font-size: 12px;
    font-weight: 600;
    color: white;
    background-color: #1f9992;
    border: none;
    border-radius: 20px 20px 20px 20px / 20px 20px 20px 20px;
}

.create-password__confirm-button:hover {
    cursor: pointer;
}

.create-password__copyright {
    padding-top: 5px;
    padding-bottom: 5px;
    align-self: flex-end;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 48px;
    font-size: 10px;
    color: white;
    background-color: #1d2934;
}

@media (min-height: 1080px) {
    .create-password__content {
        margin-bottom: 15%;
    }
}
