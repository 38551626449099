.main {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    background-color: #f7f8f8;
}

.main__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* height: 100%; */
    flex-grow: 1;
    overflow: hidden;
    position: relative;

    background: #f7f8f8;
}

.main__content {
    display: flex;
    flex-direction: column;
    position: relative;
    padding-left: 80px;
    margin: 0 auto;
    /* height: 100%; */

    box-sizing: border-box;

    height: calc(100vh - 68px);
    width: 100%;
    max-width: 2000px;

    transition: all 0.25s ease-out;
}

.navbar.expanded ~ .main__content {
    /* transform: translateX(170px); */
    padding-left: 250px;
}

.main__header {
    display: flex;
    justify-content: space-between;
    padding-top: 3em;
}

.main__header__menu-icon {
    display: none;
}

.main__header__title-container {
    margin-right: auto;
    display: flex;
}

.main__header__title-container .MuiSvgIcon-root {
    align-self: center;
    height: 1em;
    font-size: 2em;
}

.main__header__title-return-icon {
    align-self: center;
    margin-top: 5px;
}

.main__header__title-return-icon.clickable {
    cursor: pointer;
}

.main__header__title-model {
    align-self: center;
    margin-left: 10px;
    font-family: "Montserrat";
    font-size: 20px;
    font-weight: 600;
    color: #1d2934;
}

.main__header__title-icon {
    margin-top: 3px;
    width: 24px;
    height: 24px;
}

.main__header__title-field {
    align-self: center;
    font-family: "Montserrat";
    font-size: 20px;
    font-weight: 400;
    color: #1d2934;
}

.main__header__options-container {
    display: flex;
    justify-content: flex-end;
}

.main__content__user {
    position: absolute;
    top: 3em;
    right: 20px;

    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 1100px) {
    .main {
        overflow-x: hidden;
        flex-direction: column;

        padding-top: 60px;
    }

    .main__content {
        padding-left: initial;
    }

    .main__header__navbar {
        z-index: 999;
    }

    .main__header__menu-icon {
        display: block;
        width: 28px;
        height: 28px;
    }

    .main__header {
        justify-content: space-between;
        margin-right: 3em;
        margin-top: initial;
    }

    .main__header__title-container {
        margin-top: 20px;
    }

    .main__content__user {
        position: fixed;
        z-index: 9999;
        top: 20px;
        right: 20px;
    }
}

@media screen and (max-width: 640px) {
    .main__header__title-field {
        font-weight: 600;
    }
}
