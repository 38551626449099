.copyright {
    font-family: "Montserrat";
    font-size: 12px;
    color: white;
}

.copyright-text {
    text-decoration: none;
    color: inherit;
}

@media screen and (max-width: 1000px) {
    .copyright {
        font-size: 16px;
    }
}
