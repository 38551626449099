.overview-risks-card {
    width: 100%;
    background-color: #fff;
    border: 1px solid #e5e6e8;
    border-radius: 10px;
    padding-bottom: 20px;
}

.overview-risks-card__header {
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    flex: 1;
    font-weight: 600;
    padding-left: 25px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 20px;

    border-bottom: 1px solid #e5e6e8;
}

.overview-risks__techniques {
    display: flex;
    width: 220px;
    padding-bottom: 10px;
    padding-left: 20px;
    position: relative;
}

.overview-risks__techniques::before {
    content: "";
    display: block;
    width: 4px;
    border-radius: 2px;
    height: 60%;
    background-color: #1c9690;
    position: absolute;
    top: 0;
    left: 0;
}

.card-header__button {
    display: block;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
}

.overview-risks-card__title {
    margin: 0;
    padding: 0;

    font-family: Roboto;
    font-size: 14px;
    color: #666d74;
}
