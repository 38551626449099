.ui-notification__container {
    font-size: 1px;

    position: fixed;
    top: 0;

    width: 100%;
    height: 0;

    display: flex;
    justify-content: center;
    align-items: center;
}

.ui-notification {
    position: fixed;
    top: -3.5em;

    z-index: 1000;

    background-color: #fff;
    border-radius: 0.8em;

    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);

    font-size: 12em;
    font-family: Montserrat;
    font-weight: 500;

    padding-left: 3em;
    padding-right: 3em;
    padding-top: 0.4em;
    padding-bottom: 0.4em;

    animation: ui-notification__animation-in 0.3s ease-out forwards;
}

.ui-notification.exit {
    animation: ui-notification__animation-out 0.3s ease-out forwards;
}

@keyframes ui-notification__animation-in {
    from {
        transform: translateY(0em);
    }
    to {
        transform: translateY(7em);
    }
}

@keyframes ui-notification__animation-out {
    from {
        transform: translateY(7em);
    }
    to {
        transform: translateY(0em);
    }
}
