.table-settings {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    font-family: "Montserrat";
}

.table-settings__container {
    width: 100%;
    height: 100%;
    display: flex;
    background: linear-gradient(131.47deg, #f8fbff 7.84%, #eff2f6 93.19%);
}

.table-settings__container__navbar {
    box-shadow: 0px 4px 10px #c0d2e4;
}

.table-settings__menu {
    box-sizing: border-box;
    width: 100%;
}

.table-settings__menu-header {
    margin-top: 50px;
    margin-left: 3%;
    display: flex;
}

.table-settings__return-icon {
    width: 15px;
    height: 15px;
    color: white;
    background-color: black;
    border-radius: 50%;
}

.table-settings__header-title {
    margin-left: 1%;
    margin-top: -2px;
    font-size: 24px;
    color: rgba(29, 41, 52, 0.5);
    width: 200px;
}

.table-settings__user-profile {
    margin-left: 500px;
}

.table-settings__card {
    width: 800px;
    border-radius: 10px;

    border: 1px solid rgba(202, 205, 208, 0.5);
    background: #fff;
    padding: 12px;
}

.table-settings__card.small {
    width: 300px;
    padding: 12px 24px;
}

.table-settings__card-title {
    font-weight: 500;
}

.table-settings__card-subtitle {
    font-size: 14px;
    margin-top: 6px;
}

.columns-checkbox-container {
    margin-top: 8px;
    max-height: 120px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.checkbox-container {
    display: flex;
    align-items: center;
    padding: 4px;
}

.checkbox {
    position: relative;
}

.checkbox-input {
    display: block;
    opacity: 0;
    cursor: pointer;
}

.checkbox-label {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 12px;
    height: 12px;
    border-radius: 2px;
    border: 1px solid rgb(0 0 0 / 60%);
}

.checkbox-text {
    font-size: 14px;
    color: black;
    cursor: pointer;
}

.table-settings__save-button__container {
    text-align: center;
}

.table-settings__save-button {
    width: 90px;
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 500;
    color: white;
    background-color: #1f9992;
    border: none;
    border-radius: 20px;
    margin: auto;
    margin-top: 10px;
}

.table-settings__save-button:disabled {
    background-color: #a7b0afe3;
    cursor: not-allowed !important;
}

.table-settings__save-button:hover {
    cursor: pointer;
}

.checkbox-disabled {
    cursor: auto;
    opacity: 0.75;
}

@media screen and (max-width: 1100px) {
    .table-settings__menu {
        padding-top: 20px;

        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media screen and (max-width: 900px) {
    .table-settings__card {
        width: 580px;
    }

    .table-settings__user-table {
        margin-left: 300px;
    }

    .table-settings__card-grid {
        grid-template-columns: 25% 35% 35%;
    }
}
