/*********** MAPBOX MAP OVERRIDES ***************/

.mapboxgl-ctrl-logo {
    display: none !important;
}

.mapboxgl-ctrl-attrib {
    display: none !important;
}

.tip-table {
    line-height: 16px;
    margin: 0 auto;
}

.tip-table th:first-child {
    font-weight: 600;
    text-align: right;
}

.crop-table__marker-popup__title {
    margin: 5px;
    margin-bottom: 10px;
    
    text-align: center;
    font-size: 20px;
    font-weight: 450;
    color: #1D2934;
}

.crop-table__marker-subtitle {
    font-weight: 450 !important;
    color: #1D2934 !important;
}

.crop-table__marker-text {
    font-weight: 450 !important;
    color: #666D74 !important;
}

.mapboxgl-popup-content {
    z-index: 999999;
    padding: 20px 22px;

    font-family: "Roboto";

    pointer-events: none;
    border-radius: 15px !important;

    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important; 
}

.mapboxgl-popup-close-button {
    display: none;
}

.mapboxgl-popup-tip {
    z-index: 9999999;
    border: none;
    width: 30px !important;
    height: 30px !important;
    border-right-width: 25px;
    border-left-width: 25px;
    background-color: white;
    transform: rotate(45deg);
    top: 18px;
    border-radius: 5px;
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
    box-shadow: 6px 6px 6px -2px rgba(0, 0, 0, 0.25) !important; 
}

/* NOTE: Not used because of the box-shadow of the popup content */
/* .mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
    box-shadow: -4px -4px 4px -3px rgba(0, 0, 0, 0.25) !important; 
} */

.mapboxgl-popup-content {
    box-shadow: none;
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-content {
    margin-top: -20px;
}

.mapboxgl-popup-anchor-top-left .mapboxgl-popup-content {
    margin-top: -23px;
    margin-left: -7px;
}

.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-content {
    margin-top: -18px;
    margin-left: -9px;
}

.mapboxgl-popup-anchor-top-right .mapboxgl-popup-content {
    margin-top: -17px;
    margin-right: -10px;
}

.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-content {
    margin-top: -17px;
    margin-right: -10px;
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-content {
    margin-bottom: -20px;
}

.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-content {
    margin-bottom: -20px;
}

.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-content {
    margin-bottom: -20px;
}

/***********  VARIABLE CONTROL BUTTONS   *****************/

.variable-controls {
    width: 100%;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    display: flex;
    padding: 10px;
    pointer-events: none;
}

.variable-controls > .variable-button {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 40px;
    min-width: 38px;
    cursor: pointer;
    background-color: #fafafa;
    pointer-events: all;
    padding: 7px;
    margin-top: 10px;
    border-radius: 10px;
}

.variable-controls > .variable-button.active {
    background-color: #1c9690;
    color: #fafafa;
}

.variable-controls > .variable-button.active path {
    fill: #fafafa;
}

.variable-controls > .variable-button:hover {
    background-color: #dedede;
}

/* Fix to controls getting overlapped in certains screen sizes / zoom */

@media (max-width: 1250px) {
    .variable-controls {
        align-items: flex-start;
    }
}

/*********** LAYER SWITCH BUTTON ***************/

.layer-switch {
    display: flex;
    justify-content: center;
    position: absolute;
    z-index: 20;
    align-items: center;
    min-height: 40px;
    min-width: 38px;
    cursor: pointer;
    background-color: #fafafa;
    pointer-events: all;
    padding: 7px;
    margin: 10px;
    border-radius: 10px;
}

.layer-switch:hover {
    background-color: #dedede;
}

/**********  LEGENDS  ***************/

.mapbox-field-legend {
    background-color: none;
    position: absolute;
    z-index: 20;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 10px;
    pointer-events: none;
}

.field-legend-gradient-wrapper {
    background-color: #f4f6f8;
    padding: 10px;
    border-radius: 10px;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-content: center;
    align-items: center;
}

.field-legend-name-wrapper {
    background-color: #f4f6f8;
    padding: 5px;
    border-radius: 5px;
}

.gradient-value {
    font-size: 14px;
    margin: 3px;
}

.gradient-bar {
    height: 130px;
    width: 20px;
    border-radius: 20px;
}
