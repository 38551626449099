.weather_variables__temp_container {
    z-index: 1310;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-around;
}

.max_temp_value {
    color: #424242;
}

.min_temp_value {
    color: #787878;
}
