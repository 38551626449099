.overview {
    position: relative;
    width: 100%;
    height: 100%;
    padding-top: 14px;
    padding-bottom: 14px;
    /* padding-left: 20px;
    padding-right: 20px; */

    font-family: Montserrat;
    font-size: 14px;

    display: flex;
    column-gap: 20px;
}

.overview * {
    box-sizing: border-box;
}

.overview__main {
    width: 800px;
    overflow: hidden;
    flex: 1;
}

.overview__main__table,
.overview__main__graph {
    width: 100%;
}

.overview__main__graph {
    background-color: #fff;
    /* margin-bottom: 20px; */
    /* padding: 20px; */
    border-radius: 10px;
    border: 1px solid #e5e5e5;
}

.overview__aside {
    flex: 1;
    max-width: 350px;
    display: flex;
    flex-direction: column;
}

.overview__table {
    width: 100%;
    overflow: hidden;
    /* position: absolute; */
    bottom: 0;
}

@media screen and (max-width: 1000px) {
    .overview {
        flex-direction: column;
    }

    .overview__aside {
        width: 100%;
        max-width: unset;
        flex-direction: column-reverse;
    }
}

.report__download-button {
    position: fixed;
    top: 12px;
    right: 75px;
    z-index: 9999;
    width: 32px;
    height: 32px;
}

.report__download-button.demo {
    right: 200px;
}
