.table-labels {
    /* width: 100%; */
    width: max-content;

    /* background-color: #000; */

    display: flex;
    align-items: center;
}

.table-labels__last-items {
    z-index: 3500;
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;

    height: 1.5em;
    border-radius: 0.75em;
    padding-left: 5px;
    padding-right: 5px;
    background-color: #b1b1b1;
}

.table-labels__last-items__value {
    font-size: 0.8em;
    color: #fff;
}

.table-labels__last-items__container {
    display: none;
    flex-wrap: wrap;

    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 199;

    width: 150px;
    padding-top: 10px;
    padding-left: 10px;

    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
}

.table-labels__last-items__container .table-label-item {
    margin-bottom: 10px;
}

.table-labels__last-items:hover .table-labels__last-items__container {
    /* .table-labels__last-items__container { */
    display: flex;
}
