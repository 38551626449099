.sidebar__fields {
    max-height: 300px;
    width: 100%;
    padding-left: 55px;
    padding-right: 14px;
    /* padding-right: 15px; */

    overflow-y: auto;
    background: white;

    border-bottom: 1px solid #e5e6e8;
}

.sidebar__field__item {
    width: 100%;
    padding-top: 8px;
    padding-bottom: 8px;
    /* border-top: 1px solid #e5e6e8; */

    background-color: white;

    font-size: 14px;
    font-family: Roboto;
    color: #666d74;
}

.sidebar__field__item.region {
    background: inherit;
    padding-left: initial;
    color: #1a232f;

    font-family: Roboto;
    font-weight: 400;
    font-size: 16px;
    padding-bottom: 16px;
    padding-top: 16px;
    position: sticky;
    top: 50px;
}

.sidebar__field__item__name {
    font-weight: 400;
}
.sidebar__field__item__crop {
    font-weight: 300;
}

.sidebar__fields *:link,
.sidebar__fields *:visited {
    font-style: none;
    text-decoration: inherit;
}

.sidebar__fields__search-bar {
    background: inherit;
    padding-top: 10px;
    padding-bottom: 10px;

    position: sticky;
    top: 0;
}
