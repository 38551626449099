.field-properties {
    margin: 0;
    padding: 15px 10px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    min-height: 110px;
    box-sizing: border-box;
    background: white;
    border: 1px solid rgba(202, 205, 208, 0.5);
    border-radius: 10px;

    margin-bottom: 20px;
}

.field-properties__mapbox {
    height: 20px;
    min-width: 110px;
    width: 100%;
    max-width: 370px;
    flex-grow: 2;
}

.field-properties__mapbox .mapContainer {
    width: 100% !important;
    height: 100%;
}

.field-properties__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    max-width: 160px;
    font-family: "Montserrat";
    font-size: 14px;
    color: #1d2934;
}

.field-properties__text {
    /* min-height: 20px; */
    padding-bottom: 5px;
}

@media screen and (max-width: 700px) {
    .field-properties__text.extension {
        display: none;
    }
}

.field-properties__text b {
    font-weight: 600;
}

/* .mapContainer.mapboxgl-map {
    width: 100%;
} */

/* Keep an eye on this one, as it overwrites the canvas style of mapboxgl */
.field-properties__mapbox .mapboxgl-canvas {
    width: 100% !important;
    height: 85px !important;
    border-radius: 10px;
}
