.risk-table {
    width: 100%;
    background: #fff;
    border-radius: 10px;
    border: 1px solid #e5e5e5;
    margin-bottom: 20px;
}

.risk-table__title {
    padding: 14px;
    font-size: 16px;
    font-family: Montserrat;
    border-bottom: 1px solid #e5e5e5;
    font-weight: 600;
}

.risk-table__content {
    position: relative;
    overflow-x: auto;
}

.risk-table__table.fixed-width {
    width: 100%;
}

.risk-table__table.fixed-width tr {
    display: inline-table !important;
}

.risk-table__table thead,
.risk-table__table tbody,
.risk-table__table tr {
    width: 100%;
    display: block;
}

.risk-table__table tr td:first-of-type,
.risk-table__table tr th:first-of-type {
    position: sticky;

    left: 0;
    background: #fff;
    border-right: none;

    min-width: 150px;
    width: 150px;
}
.risk-table__table tr td:nth-child(2),
.risk-table__table tr th:nth-child(2) {
    position: sticky;
    z-index: 10;
    left: 0;
    background: #fff;

    border-left: 1px solid #e5e5e5;

    min-width: 150px;
    width: 150px;
}

.risk-table__table td,
.risk-table__table th {
    position: relative;
    background-color: #fff;
    min-width: 240px;
    width: 240px;
    margin: 0 !important;
    padding: 14px;

    border-right: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
}

/* .risk-table__table td {
    
} */

.risk-table__table__header,
.risk-table__table__cell {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.risk-table__table__header__title,
.risk-table__table__header__subtitle {
    display: flex;
    justify-content: center;
    align-items: center;

    margin: 0;
    font-size: 14px;
    font-weight: 400;
}

.risk-table__table__header__title__icon {
    width: 22px;
    height: 22px;
    margin-right: 10px;
    opacity: 0.5;
}

.risk-table__table__header__subtitle {
    margin-top: 10px;
    color: #b3b6ba;
}

.greyed-out {
    opacity: 0.4;
    color: #a4a4a4;
}

@media screen and (max-width: 1000px) {
    .risk-table__table tr td:first-of-type,
    .risk-table__table tr th:first-of-type,
    .risk-table__table tr td:nth-child(2),
    .risk-table__table tr th:nth-child(2) {
        position: relative;
    }
}
