.navbar {
    width: 80px;
    height: 100%;

    background: #fff;
    border-right: 1px solid #e5e6e8;

    position: fixed;
    top: 0;
    z-index: 9999;

    overflow: hidden;
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    display: flex;
    flex-direction: column;

    transition: all 0.25s ease-out;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.navbar::-webkit-scrollbar {
    display: none;
}

.navbar__logo {
    width: 100%;
    padding-top: 25px;
    padding-bottom: 20px;

    background: #fff;

    position: sticky;
    top: 0;
    z-index: 99;

    display: flex;
    justify-content: center;
    align-items: center;
}

.navbar__content {
    width: 100%;
    flex-grow: 1;
    box-sizing: border-box;
    background: #fff;

    display: flex;
    flex-direction: column;
}

.navbar__content *,
.navbar__item__children * {
    margin: 0;
    padding: 0;

    box-sizing: border-box;
}

.navbar__item {
    display: block;
    list-style: none;
    position: relative;

    box-sizing: border-box;
    background: #fff;

    padding-left: 15px;
    padding-right: 15px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    width: 100%;
    height: 50px;

    cursor: pointer;

    transition: all 0.25s ease-out;
}

.navbar__item.active::after {
    content: "";
    display: block;
    position: absolute;
    width: 4px;
    height: 50%;
    border-radius: 2px;

    left: 2px;
    background: #f37920;
}

.navbar__item svg {
    fill: #7790a8;
}
.navbar__item.active svg {
    fill: #f37920;
}

.navbar__item__content {
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    border-top: 1px solid #e5e6e800;

    display: flex;
    align-items: center;

    transition: all 0.25s ease-out;
}

/* NOTE: overrided by the MaterialPopover target class, leaving comment for clarity and in case of further changes */
/* .navbar__item:first-of-type .navbar__item__content {
    padding-top: 20px;
    border-top: 1px solid #e5e6e8;
} */

.navbar .material-popover-target:first-of-type .navbar__item__content {
    padding-top: 20px;
}

/* Used for the border between the logo and Home item */
.navbar .material-popover-target:first-of-type::before {
    content: "";
    position: absolute;
    right: 15px;
    left: auto;
    width: 60%;
    height: 1px;
    background-color: #e5e6e8;
    overflow: visible;
    z-index: 9999;
}

.navbar.expanded .material-popover-target::before {
    content: "";
    position: absolute;
    right: 15px;
    left: auto;
    width: 85%;
    height: 1px;
    background-color: #e5e6e8;
    overflow: visible;
    z-index: 9999;
}

.navbar__item__icon {
    width: 22px;
    height: 22px;
    margin-left: 14px;

    transition: all 0.25s ease-out;
}

.navbar__item__text {
    position: absolute;

    width: 140px;
    margin-left: 44px;

    background: none;
    border: none;
    outline: none;
    cursor: pointer;

    font-size: 14px;
    font-family: Roboto;
    text-align: left;
    opacity: 0;

    transition: all 0.25s ease-out;
}

.navbar.expanded {
    width: 200px;
}

.navbar.expanded .navbar__item__content {
    /* border-top: 1px solid #e5e6e8ff; */

    padding-top: 15px;
    padding-bottom: 15px;
}

.navbar__item__icon-container {
    border: none;
    background: none;
    outline: none;

    pointer-events: none;
}

.navbar.expanded .navbar__item__icon-container {
    pointer-events: initial;
    cursor: pointer;
}

.navbar.expanded .navbar__item__text {
    opacity: 1;
}

.navbar.expanded .navbar__item__children {
    display: flex;
    flex-direction: column;
}

.navbar__item.expanded .navbar__item__text {
    /* color: #f37920; */
    font-weight: bold;
}

.navbar__item.expanded svg {
    /* fill: #f37920; */
}

.navbar__item__children {
    width: 100%;
    background: #f1f4f6;

    display: none;
}

.navbar__item__children.grow {
    /* flex-grow: 1; */
    overflow-y: scroll;
    overflow-x: hidden;

    max-height: 300px;
}

.navbar__item__children__container {
    width: 100%;
    height: 100%;

    background: inherit;
}

.mobile-header {
    z-index: 9999;
    box-sizing: border-box;
    padding: 10px 20px;
    display: none;

    position: fixed;
    width: 100%;
    height: 60px;
    top: 0;

    justify-content: space-between;
    background: linear-gradient(0deg, #ffffff, #ffffff);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.mobile-header__navbar {
    z-index: 999;
    position: fixed;
    height: 100%;
}

.mobile-header__icon {
    margin: auto 0;
    height: 25px;
    width: 25px;
}

.navbar__bottom {
    margin-top: auto;
    bottom: 0px;
    width: 100%;
}

@media screen and (max-width: 1100px) {
    .navbar {
        transform: translateX(-100%);
    }

    .navbar.expanded {
        /* display: flex; */
        transform: translateX(0);
    }

    .mobile-header {
        display: flex;
    }
}
