.control-item {
    width: 100%;
    height: 100%;
}

.control-item__header {
    padding-bottom: 8px;
    border-bottom: 1px solid #505050;
}

.control-item__title {
    font-weight: bold;
}

.control-item__content {
    padding-top: 8px;
}

.control-item__content__default-options__option {
    display: block;
    transition: transform 0.2s ease-out;
}

.control-item__content__default-options__option:disabled {
    pointer-events: none;
}
.control-item__content__default-options__option:hover {
    font-weight: bold;
    transform: translateX(5px);
}

.control-item__header {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.control-item__title,
.control-item__content__default-options__option {
    position: relative;

    border: none;
    background: none;
    outline: none;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 0px;
    width: 100%;
    text-align: left;

    cursor: pointer;

    font-size: 14px;
    font-family: Roboto;

    display: flex;
    align-items: center;
}
