.table-label-button {
    position: relative;
}

.table-label-button__button {
    width: 32px;
    height: 32px;
    border-radius: 16px;
    padding: 5px;
    padding-left: 6px;

    border: none;
    background: none;
    outline: none;

    display: flex;
    align-items: center;

    cursor: pointer;

    transition: all 0.2s ease-out;
}

.table-label-button__button.active,
.table-label-button__button:hover {
    background-color: #ddd;
}
