.chart-grid {
    display: grid;
    grid-template-columns: 20px 1fr minmax(120px, auto);
    grid-gap: 30px;
}

.chart-block {
    display: grid;
    grid-template-columns: auto calc(100% - 40px);
}

.flexible-chart {
    width: calc(100% - 40px);
}

p.y-label {
    writing-mode: vertical-lr;
    text-align: center;
    transform: rotate(180deg);
    color: #1c9690;
}

.y-axis .rv-xy-plot__axis__tick__text {
    fill: #1c9690 !important;
}

.headerBlocksContainer {
    margin-top: 20px;
    margin-bottom: 10px;
    display: grid;
    /*grid-template-columns: repeat(5, minmax(135px, 1fr)) 46px minmax(196px, 1fr);*/
    grid-template-columns: 1fr 4fr 50px minmax(250px, 1fr);
}

/* TODO: Better naming convention */
.headerBlockContainer {
    /* margin-right: 18px; */
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 90px;
    width: 110px;
    box-shadow: 0px 4px 10px #c0d2e4;
    border-radius: 5px;
    transition: 200ms;
    border: 1px solid #1c9690;
    cursor: pointer;
}

.headerBlockContainer:last-child {
    margin-right: 0;
}

.headerBlockContainer.profileBlock {
    border: 0;
    padding: 5px 11px;
    height: 54px;
}

.profileText {
    font-size: 8px;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
}

.profileTextBold {
    font-size: 8px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    margin-top: 0;
    margin-bottom: 4px;
}

.profileTextBold:last-child {
    margin-bottom: 0;
}

.profileTextContainer {
    margin-right: 17px;
}

.headerBlock {
    box-shadow: none !important;
    background: transparent !important;
}

.blocks-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-right: 18px;
}

.textBlockContainer {
    box-shadow: 0px 4px 10px rgba(56, 78, 99, 0.2);
    border-radius: 20px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #1f9992;
    cursor: pointer;
    transition: 200ms;
    position: relative;
}

.textBlockContainer:hover {
    box-shadow: 0px 4px 10px #1c9690;
}

p.textBlock {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 10px;
    text-align: center;
    color: #1d2934;
}

.dotIcon {
    padding-right: 0;
    padding-left: 0;
    width: 24px;
}

.title-container {
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
}

p.main-title {
    font-family: "Montserrat-Bold", sans-serif;
    font-size: 24px;
}

.main-titles {
    font-family: "Montserrat-Bold", sans-serif;
    font-size: 24px !important;
    fill: #263238 !important;
}

.main-titles text {
    font-family: "Montserrat-Bold", sans-serif;
    font-size: 12px !important;
    fill: #263238 !important;
}

.desc-card {
    padding: 13px 22px 7px 31px;
    box-shadow: 0px 4px 10px rgba(56, 78, 99, 0.2);
    border-radius: 10px;
    display: inline-flex;
    align-items: center;
}

.profileTextContainer {
    margin-right: 20px;
}

p.profile-bold {
    font-family: "Montserrat-Bold", sans-serif;
    font-size: 18px;
}

span.profile-light {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    margin-left: 10px;
}

.btns-container {
    margin-bottom: 10px;
}

@media screen and (max-width: 1100px) {
    .btns-container {
        display: none !important;
    }
}

.content-dropdown {
    padding: 30px 10px 10px 10px;
    box-shadow: 0px 4px 10px rgba(56, 78, 99, 0.2);
    width: 96px;
    border-radius: 0 0 10px 10px;
    background: #fff;
    margin-top: -20px;
    flex-direction: column;
    display: flex;
}

button.button-dropdown {
    background: #f3f7fb;
    display: flex;
    border: 1px solid #1c9690;
    border-radius: 10px;
    font-size: 14px;
    font-family: Roboto;
    padding: 8px 12px;
    color: #1c9690;
    /* width: 116px; */
    justify-content: space-between;
    align-items: center;
    position: relative;
    /* z-index: 999; */
    font-weight: 600;
}

button.button-dropdown.active {
    box-shadow: 0px 4px 10px #c0d2e4;
}

button.button-dropdown > img {
    padding-left: 10px;
}

button.button-dropdown:focus {
    outline: none;
}

.buttonDropdownLeftSide {
    display: flex;
    align-items: center;
}

.buttonDropdownLeftSide > img {
    padding-right: 10px;
    width: 28px;
    height: 18px;
}

button.export-now {
    border: 0;
    background: #1c9690;
    border-radius: 20px;
    color: #fff;
    font-family: "Roboto", sans-serif;
    margin-top: 10px;
    font-size: 14px;
    font-weight: 600;
    padding: 4px 10px;
    margin-bottom: 10px;
    cursor: pointer;
}

button.export-now:focus {
    outline: none;
}

.dropdown-item {
    display: grid;
    grid-template-columns: 10px auto;
    align-items: center;
    margin-bottom: 10px;
}

label.dropdown-item-text {
    padding-left: 10px;
    font-family: "Roboto", sans-serif;
    font-size: 10px;
    margin: 0;
}

p.dropdown-item-text {
    padding-left: 10px;
    font-family: "Roboto", sans-serif;
    font-size: 10px;
    margin: 0;
}

span.dropdown-item-text-bold {
    font-weight: 700;
}

.hidden-link {
    display: none;
}

.more-content-dropdown {
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    background: #fff;
    flex-direction: column;
    display: flex;
}

.more-item-dropdown {
    padding: 6px 10px;
    font-size: 10px;
    font-family: "Roboto", serif;
    color: #1d2934;
    cursor: pointer;
    position: relative;
}

.more-item-dropdown:nth-child(even) {
    background: #e7e7e7;
}

.more-item-dropdown:last-child {
    border-radius: 0 0 5px 5px;
}

.mainProfileBlockContainer {
    display: flex;
    justify-content: flex-end;
}

.mainProfileBlock {
    display: flex;
    align-items: flex-end;
}

.mainProfileBlockText {
    margin-left: 6px;
}

p.mainProfileBlockTitle {
    font-family: "Montserrat", serif;
    color: #1d2934;
    font-size: 10px;
    font-weight: 600;
    margin: 0;
}

p.mainProfileBlockDesc {
    font-family: "Montserrat", serif;
    color: #505c66;
    font-size: 8px;
    font-weight: 400;
    margin: 0;
    padding-bottom: 2px;
}

.slider-container {
    margin-top: 30px;
}

.Toastify__toast-body {
    font-family: "Montserrat";
}

@media screen and (min-width: 600px) and (max-width: 1100px) {
    .headerBlocksContainer {
        grid-template-columns: 25% 68% 5%;
    }

    .headerBlockContainer {
        height: 90px;
    }

    .blocks-container {
        padding-top: 20px;
    }

    .textBlockContainer {
        height: 38px;
    }
    .textBlockContainer p {
        font-size: 18px;
    }

    .chart-actions__dropdown-button {
        /* margin-top: 120px; */
        margin-top: auto;
        margin-bottom: auto;
    }
}

@media screen and (min-width: 900px) and (max-width: 1100px) {
    /* .headerBlockContainer {
        width: 150px;
    } */
}
