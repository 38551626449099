.new-password-modal {
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;

    font-family: "Montserrat";

    background-color: rgba(0, 0, 0, 0.5);
}

.new-password-modal__container {
    position: relative;
    bottom: 100px;
    padding-top: 40px;
    padding-left: 23px;
    padding-bottom: 30px;

    display: flex;
    flex-direction: column;

    width: 400px;
    height: max-content;

    background-color: #fff;

    border-radius: 10px;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
}

.new-password-modal__title {
    font-size: 12px;
    font-weight: 500;
    color: rgba(29, 41, 52, 0.85);
}

.new-password-modal__input {
    padding-top: 10px;
    padding-left: 10px;

    width: 90%;

    font-family: "Roboto";
    line-height: 1.3;
    font-size: 10px;
    font-weight: 400;
    color: rgba(29, 41, 52, 0.7);

    background-color: transparent;
    border: 0;
    border-bottom: 2px solid rgba(28, 150, 144, 0.6);
}

.new-password-modal__input::placeholder {
    color: rgba(80, 80, 80, 0.8);
}

.new-password-modal__button-container {
    margin-top: 10px;
    margin-right: 30px;

    display: flex;
    justify-content: flex-end;
}

.new-password-modal__cancel-button {
    margin-right: 20px;

    width: 100px;
    height: 15px;

    line-height: 1.7;
    font-size: 10.5px;
    font-weight: 400;
    color: white;

    background-color: #f47920;
    border: none;
    border-radius: 20px 20px 20px 20px / 20px 20px 20px 20px;
}

.new-password-modal__cancel-button:hover {
    cursor: pointer;
}

.new-password-modal__accept-button {
    width: 100px;
    height: 15px;

    line-height: 1.7;
    font-size: 10.5px;
    font-weight: 400;
    color: white;

    background-color: #1c9690;
    border: none;
    border-radius: 20px 20px 20px 20px / 20px 20px 20px 20px;
}

.new-password-modal__accept-button:hover {
    cursor: pointer;
}
