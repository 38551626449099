.sign-in__screen {
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    font-family: "Montserrat";
    background-image: url("../assets/images/sign_in.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    overflow-y: auto;
}

.sign-in {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    padding: 100px;
}

.sign-in__card {
    background-color: white;
    border-radius: 8px;
    max-width: 400px;
    width: 100%;
    min-height: 400px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: center;
}

.sign-in__card-title {
    margin-top: 20px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #1a232f;
}

.sign-in__logo {
    width: 230px;
    height: 75px;
    margin-top: 16px;
}

.sign-in__demo-button {
    border-color: #ee6900;
    background-color: #ee6900;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: white;
    width: 70%;
    text-align: center;
    padding: 10px;
    margin-top: 10px;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.1s;
}
.sign-in__demo-button:hover {
    opacity: 0.85;
}

.sign-in__hr {
    margin-top: 22px;
    height: 1px;
    width: 70%;
    border-top: 1px solid #e4e6e8;
}

.sign-in__title {
    margin-top: 16px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #1a232f;
}

.sign-in__form {
    width: 70%;
}

.sign-in input:focus {
    background-color: white;
    outline: 1px solid #b3b6ba;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.sign-in__input-group {
    margin-top: 12px;
    width: 100%;
}
.sign-in__input-group label {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #666d74;
}
.sign-in__input-group input {
    margin-top: 6px;
    margin-bottom: 4px;
    border-radius: 8px;
    border: 1px solid #e6e7e8;
    width: 100%;
    padding: 8px;
    padding-left: 12px;
    box-sizing: border-box;
}

.sign-in__restore-password {
    color: #ee6900;
    text-decoration: none;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    opacity: 0.85;
}
.sign-in__restore-password:hover {
    text-decoration: underline;
}

.sign-in__button {
    border-radius: 8px;
    border-color: #ee6900;
    background-color: #ee6900 !important;
    border-style: solid;
    border-width: 1px;
    width: 70%;
    padding: 8px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    color: white;
    cursor: pointer;
    box-sizing: border-box;
    text-decoration: none;
    flex: 0;
    transition: all 0.1s;
}
.sign-in__button:hover {
    background-color: #bd5300 !important;
}
.sign-in__button.submit {
    margin-top: 22px;
    width: 100%;
}

.sign-in__sign-up-text {
    margin-top: 20px;
    font-family: "Roboto";
    font-size: 16px;
    font-weight: 400;
    color: #1a232f;
}

.sign-in__sign-up-button {
    box-sizing: border-box;
    margin-top: 20px;
    padding: 8px;
    flex: 0;

    width: 70%;

    text-decoration: none;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    color: #ee6900;

    border-color: white;
    background-color: white !important;
    border: 2px solid #fef2e9;
    border-radius: 8px;

    transition: all 0.1s;
}

.sign-in__sign-up-button:hover {
    border-color: #ee6900;
}

.sign-in__sign-up-rights {
    margin-top: 50px;
    margin-bottom: 34px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #666d74;
}

.sign-in__screen .locale {
    margin-top: 20px;
}

.localization {
    margin-top: 22px;
    height: 1px;
    width: 70%;
}

@media screen and (max-width: 500px) {
    .sign-in {
        padding: 0px;
        height: 100%;
    }
    .sign-in__card {
        max-width: none;
        width: 100%;
        height: 100%;
        border-radius: 0px;
    }
}

.sign-in-side__checkbox-container {
    position: relative;
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: max-content;
}

.sign-in-side__checkbox {
    position: relative;
    display: inline-block;
    margin-left: 1px;
    margin-right: 5px;
    margin-top: 5px;
    width: 12px;
    height: 12px;
    border-radius: 2px;
    border: 1px solid gray;
}

.sign-in-side__checkbox-input {
    width: 12px;
    height: 12px;
    display: block;
    position: relative;
    z-index: 99;
    opacity: 0;
}

.sign-in-side__checkbox-input:hover {
    cursor: pointer;
}

.sign-in-side__checkbox-label {
    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 12px;
    height: 12px;
    border-radius: 2px;
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.sign-in-side__checkbox-input:checked + .sign-in-side__checkbox-label {
    border: none;
    background-color: #666d74;
}

.sign-in-side__checkbox-text {
    position: absolute;
    padding-left: 20px;
    padding-top: 4px;
    width: 200px;

    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #666d74;
}

/* This is used for the Restore Password and Sign Up flow */
.confirmation-screens__logo {
    padding-bottom: 40px;
    align-self: center;
    width: 230px;
    height: 110px;
}
