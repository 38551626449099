.scenario-selector {
    padding: 13px 20px;
    width: 100%;
    box-sizing: border-box;

    display: flex;
    column-gap: 10px;
    overflow-x: auto;

    border-bottom: 1px solid #e5e5e5;
}

.scenario-selector__button {
    background: none;
    border: none;
    border-right: 1px solid #e5e5e5;
    padding: 5px;
    padding-left: 0;
    padding-right: 10px;

    display: flex;
    align-items: center;
    column-gap: 8px;

    cursor: pointer;
}

.scenario-selector__button:last-of-type {
    border-right: none;
}

.scenario-selector__button.non-visible {
    opacity: 0.3;
    text-decoration: line-through;
}
