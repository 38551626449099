.alert-icon {
    position: relative;
}

.alert-icon__indicator {
    border-radius: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ff3d3d;

    width: 18px;
    height: 18px;

    font-family: Montserrat;
    font-weight: bold;
    font-size: 11px;
    color: #fff;
}

.thermometer-up-icon {
    display: flex;
    justify-content: center;
    align-items: center;
}

.thermometer-down-icon {
    display: flex;
    justify-content: center;
    align-items: center;
}

.heat-soil-icon {
    display: flex;
    flex-direction: column;
}

.heat-soil-icon__sun {
    margin-left: 3px;
    margin-top: 1px;
    height: 6px;
}

.heat-soil-icon__soil {
    margin-left: 1px;
    margin-bottom: 2px;
}

.cold-soil-icon {
    display: flex;
    flex-direction: column;
}

.cold-soil-icon__snow {
    margin-left: 1px;
    margin-top: 1px;
    height: 6px;
    width: 8px;
}

.cold-soil-icon__soil {
    margin-bottom: 2px;
}
