.card-item {
    width: 100%;
    position: relative;
}

.card-item__main {
    display: flex;

    padding-left: 25px;
    padding-top: 15px;
    padding-right: 20px;
    padding-bottom: 15px;
}

.card-item__actionable {
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-item__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 20px;
}

.card-item__toggle {
    outline: none;
    background: none;
    border: none;
    cursor: pointer;
    border-radius: 12px;

    width: 24px;
    height: 24px;
    padding: 5px;

    transition: all 0.2s ease-out;
}

.card-item__toggle:hover {
    background: #e6e6e6;
}

.card-item__toggle.open {
    transform: rotateZ(180deg);
}

.card-item__children {
    padding-left: 65px;
    padding-right: 20px;
    /* border-bottom: 1px solid #ddd; */
}

.card-item__children p {
    margin: 0;
}

.card-item.scroll .card-item__children {
    max-height: 264px;
    overflow-y: auto;
    scroll-behavior: smooth;
}

.card-item__children__item {
    display: flex;

    padding-top: 15px;
    padding-bottom: 15px;
}

.card-item.disabled {
    opacity: 0.3;
}
