.scenario-form__icon {
    width: 22px;
    height: 22px;
}

.scenarios__new-scenario input,
.scenarios__new-scenario select,
.planting-tool-chart__header .react-datepicker-wrapper input {
    border: none;
    background: none;
    outline: none;

    display: block;
    width: 100%;

    border: 1px solid #c4c4c4;
    border-radius: 8px;
    padding: 8px 10px;
}

.scenarios__new-scenario input:disabled,
.scenarios__new-scenario select:disabled {
    background-color: #efefef;
}

.scenarios__new-scenario input[type="date"] {
    padding-top: 6px;
    padding-bottom: 5px;
}

.scenarios__new-scenario input.error {
    border: 1px solid #da3849;
}

.scenario-form__input-errors {
    /* padding-top: 5px; */
    padding-bottom: 10px;
    color: #da3849;
    font-size: 12px;
}

.scenario-form__input-errors.right {
    text-align: right;
}

.scenario-form__alerts {
    max-height: 400px;
    overflow-y: auto;
    scroll-behavior: smooth;
}

.scenario-form__planting {
    display: flex;
    column-gap: 10px;
    font-size: 14px;
    font-family: Roboto;
    color: #a4a4a4;
}

.scenario-form__planting__day {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-width: 140px;
}
.scenario-form__planting__length {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-width: 140px;
}

.scenario-form__planting input {
    margin-top: 5px;
}

.scenario__risk__row {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    padding-top: 10px;
    font-size: 14px;
    font-family: Roboto;
    color: #a4a4a4;
}

.scenario__risk__container {
    /* width: 140px; */
    display: flex;
    align-items: center;
    padding-left: 10px;
    color: #000;
}

.scenario__risk__container:last-of-type {
    width: 150px;
}

.scenario__risk__container.threshold input {
    margin: 0px 5px;
}

.scenario__risk__container {
    width: unset;
}

.scenario__risk__container.lag:last-of-type {
    width: 80px;
}

/* .scenario__risk__container.lag input {
    width: 70px;
} */

.scenarios__new-scenario__color-picker {
    outline: none;
    background: none;
    border: none;
    padding: 0 !important;
    width: initial;

    cursor: pointer;

    position: relative;
}

.scenario-form__advanced-settings {
    border: none;
    background: none;
    outline: none;
    cursor: pointer;
    padding: 0;
    color: #a4a4a4;
    text-decoration: underline;
    margin-bottom: 10px;
    margin-top: 10px;
}
