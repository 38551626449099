.icon-button {
    width: 100%;
    height: 26px;

    position: relative;

    border-radius: 13px;
    background: var(--color-climate-green);

    display: flex;
    justify-content: center;
    align-items: center;
}

.icon-button__file-input {
    position: absolute;

    border: none;
    background: none;
    outline: none;
    opacity: 0;

    width: 100%;
    height: 100%;
    cursor: pointer;
}

.icon-button__button {
    position: absolute;

    border: none;
    background: none;
    outline: none;
    opacity: 0;

    width: 100%;
    height: 100%;
    cursor: pointer;
}

.icon-button__content {
    font-size: 14px;
    font-weight: bold;
    color: white;
}
