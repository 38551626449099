.report-empty {
    width: 100%;
    /* height: 100%; */
    flex: 1;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 80px;
}

.report-empty__content {
    width: 100%;
    max-width: 320px;
}

.report-empty__icon {
    width: 64px;
    height: 64px;
    margin-bottom: 40px;
}

.report-empty__title {
    font-family: Montserrat;
    font-size: 18px;
    margin-bottom: 30px;
    font-weight: 600;
}
.report-empty__description {
    font-family: Roboto;
    font-size: 16px;
    line-height: 26px;
}
