.chart-labels {
    padding: 13px 20px;
    width: 100%;
    box-sizing: border-box;

    display: flex;
    column-gap: 10px;
    overflow-x: auto;

    border-bottom: 1px solid #e5e5e5;
}
