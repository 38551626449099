.dmoptions {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 18px; 
    height: 100%;
    width: 20%;
    max-width: 150px;
    /* width: 30%; */
}

.dmoptions__button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    border-radius: 5px;
    transition: 200ms;
}

.dmoptions__button:hover {
    cursor: pointer;
    box-shadow: 0px 4px 10px #C4C4C4;
}

.dmoptions__button--disabled {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    cursor: no-drop;
    opacity: 15%;
}

.dmoptions__text {
    font-family: "Montserrat";
    font-size: 12px;
    font-weight: 600;
    text-align: center;
}

@media screen and (max-width: 1150px) {
    .dmoptions {
        justify-content: center;
        gap: 20px;
        min-width: 150px;
    }
}

@media screen and (max-width: 680px) {
    /* This is for the .chart-action row layout */
    .dmoptions {
        grid-column: 1 / 3;
        grid-row: 2 / 3;
        margin: 0;
        margin-top: 20px;
        min-width: 100%;
    }
}