.profile-settings {
    box-sizing: border-box;

    border-radius: 10px;
    border: 1px solid rgba(202, 205, 208, 0.5);
    background: #fff;

    width: 100%;
    height: 100%;
    max-width: 600px;
    margin: 0 auto;
    margin-top: 20px;

    border-radius: none;
}

.profile-settings,
.profile-settings * {
    box-sizing: border-box;
    font-size: 14px;
}

.profile-settings__grid {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.profile-settings__image-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.profile-settings__user-profile__icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    text-transform: uppercase;
    font-size: 50px;
    font-weight: 600;
    color: white;
    background-color: var(--color-orange-climate);
    border-radius: 50%;
}

.profile-settins__user-profile__icon.green {
    border: 2px solid var(--color-climate-green);
    color: var(--color-climate-green);
}

.profile-settings__image-column__image {
    margin-top: 30px;
    width: 100px;
    height: 100px;
    border-radius: 50%;
}

.profile-seetings__image-column__edit-icon-container {
    position: relative;
    z-index: 100;
    left: 32px;
    bottom: 27px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
    background-color: #1c9690;
    border-radius: 50%;
}

.profile-seetings__image-column__edit-icon-container:hover {
    cursor: pointer;
}

.profile-settings__info-column {
    padding: 0px 20px;
    display: flex;
    flex-direction: column;
}

.profile-settings__info-column__title-text {
    padding-top: 20px;
    font-weight: 600;
    color: #1d2934;
}

.profile-settings__info-column__info-container {
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #1c9690;
}

.profile-settings__info-column__info-container.error {
    border-bottom: 1px solid var(--color-negative-alert);
}

.profile-settings__info-column__info-text {
    font-weight: 400;
    color: #000000;
    border: 0px;
    width: 75%;
    padding-bottom: 5px;
}

.profile-settings__info-column__info-text:disabled {
    color: #3a3a3a;
    background: none;
}

.profile-settings__info-column__edit-icon-container {
    margin-right: 3%;
    height: 13px;
    width: 13px;
}

.profile-settings__info-column__edit-icon-container:hover {
    cursor: pointer;
}

.profile-settings__info-column__edit-confirmation-container {
    display: flex;
    gap: 5px;
    margin-left: -20px;
    height: 20px;
    width: max-content;
}

.profile-settings__info-column__edit-confirmation-container__confirm {
    width: 15px;
    height: 15px;
}

.profile-settings__info-column__edit-confirmation-container__cancel {
    width: 15px;
    height: 15px;
}

.profile-settings__info-column__info-container__error-message {
    position: absolute;
    font-size: 12px;
    color: red;
}

.profile-settings__info-column__info-password {
    font-family: "Roboto";
    padding-bottom: 5px;
    font-weight: 400;
    color: #505050;
}

.profile-settings__info-column__change-password-option {
    align-self: flex-end;
    margin-top: 10px;
    color: #1c9690;
    text-decoration: none;
    cursor: pointer;
}

.profile-settings__unit-column {
    padding: 0px 20px 20px 20px;
    display: flex;
    flex-direction: column;
}

.profile-settings__unit-column__title {
    padding-top: 20px;
    font-weight: 600;
    color: #1d2934;
}

.profile-settings__unit-column__text {
    margin-top: 10px;
    font-weight: 500;
    color: #1d2934;
}

.profile-settings__button-container {
    margin-top: 20px;
    display: flex;
    flex-direction: row;

    column-gap: 20px;
}

.profile-settings__button {
    display: flex;

    width: 120px;
    height: 30px;
    padding: 10px;
    background-color: white;
    border-radius: 15px;
    border: 1px solid rgba(0, 0, 0, 0.5);

    justify-content: center;
    align-items: center;
}

.profile-settings__button:hover {
    cursor: pointer;
}

.profile-settings__button.selected {
    background-color: #1c9690;
}

.profile-settings__button-text {
    font-family: "Montserrat";
    color: #1d2934;
}

.profile-settings__button.selected .profile-settings__button-text {
    font-family: "Montserrat";
    font-weight: 600;
    color: white;
}

.profile-settings__button-icon {
    margin-left: auto;
    width: 16px;
    height: 16px;
    background-color: rgba(29, 41, 52, 0.25);
    padding: 4px;
    border-radius: 50%;
}

.profile-settings__button.selected .profile-settings__button-icon {
    background-color: #fff;
}
.profile-settings__button.selected .profile-settings__button-icon::after {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: #1c9690;
}

.hidden {
    display: none;
}

@media screen and (min-width: 1100px) {
    .profile-settings {
        margin: 0;
        max-width: initial;
        height: initial;
    }

    .profile-settings__grid {
        flex-direction: row;
    }

    .profile-settings__image-column,
    .profile-settings__info-column,
    .profile-settings__unit-column {
        flex: 1;
    }

    .profile-settings__info-column {
        padding-bottom: 20px;
    }

    .profile-settings__image-column {
        justify-content: center;
        align-items: center;
    }
}
