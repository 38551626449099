.report-detail {
    flex: 1;
    /* max-width: 1000px; */
    height: 100%;
    width: 100%;
    padding-top: 20px;
    padding-right: 20px;
    padding-bottom: 20px;

    display: flex;
    flex-direction: column;
    align-items: center;

    overflow-y: auto;
}

.report-detail__container {
    max-width: 1200px;
}

.report-detail__image {
    display: block;
    width: 100%;
}
.report-detail__title {
    font-size: 16px;
    font-family: Montserrat;
    font-weight: 600;
    color: #1d2934;
    /* padding-top: 20px; */
    padding-left: 24px;
}

.report-detail__subtitle {
    font-size: 14px;
    font-family: Roboto;
    font-weight: 400;
    color: #666d74;
}

.report-detail__report,
.report-detail__notes {
    width: 100%;
    background-color: #fff;

    border: 1px solid #e5e5e5;
    border-radius: 10px;
    overflow: hidden;
}

.report-detail__report__header,
.report-detail__notes__header {
    width: 100%;
    height: 45px;
    border-bottom: 1px solid #e5e5e5;
    display: flex;
    justify-content: space-between;
}

/* .report-detail__report__content {

} */

.report-detail__notes {
    margin-top: 20px;
}

.report-detail__notes__textarea {
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    display: block;
    width: 100%;
    height: 120px;
    border: none;
    background: none;
    outline: none;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
}

.report-detail__report__header__right-controls,
.report-detail__report__header__left-controls,
.report-detail__notes__header__right-controls,
.report-detail__notes__header__left-controls {
    height: 100%;
    padding-left: 20px;
    padding-right: 20px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.report-detail__report__header__right-controls__icon,
.report-detail__report__header__left-controls__icon,
.report-detail__notes__header__right-controls__icon {
    border: none;
    background: none;
    outline: none;
    cursor: pointer;

    width: 24px;
    height: 24px;
    padding: 2px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.report-detail__report__header__left-controls__icon {
    padding: 4px;
    margin-right: 20px;
}

.report-detail__report__header__left-controls__text,
.report-detail__notes__header__left-controls__text {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
}

.report-detail__notes__header__left-controls__date {
    font-family: Roboto;
    font-size: 14px;
    color: #b3b6ba;
    padding-left: 10px;
    font-weight: 400;
}

.report-detail__notes__header__right-controls__icon {
    margin-left: 10px;
}

.report-detail__notes__header__right-controls__icon {
    padding: 2px;
}

.report-detail__notes__header__right-controls__icon:disabled,
.report-detail__report__header__right-controls__icon:disabled {
    opacity: 0.2;
    cursor: initial;
}
