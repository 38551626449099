.popover__container {
    position: relative;
}

.popover__label {
    z-index: 99999;
    position: relative;
    padding: 5px 8px;

    max-width: 200px;
    height: max-content;

    font-family: "Roboto";
    font-size: 14px !important;
    font-weight: 400;
    text-align: center;
    color: white;

    border-radius: 10px;
    background: #333;
    box-shadow: none !important;
}

.simple-popover {
    position: absolute;
    z-index: 99999;
    width: max-content;
    height: max-content;
    /* max-height: 100%; */
    display: none;
}

.popover__target:hover + .simple-popover {
    display: block;
}

.simple-popover.down.left {
    top: 20px;
    right: 100px;
}

.simple-popover__label {
    z-index: 9999;
    position: absolute;
    padding: 18px 14px 14px 12px;
    margin-top: 15px;

    width: max-content;
    max-width: 200px;

    font-family: "Roboto";

    background: white;
    border-radius: 8px;
    border: 1px solid #E4E6E8;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

/* NOTE: This is commented because the popover doesn't center right */
/* .simple-popover__tip {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    align-self: flex-start;
    top: 0;
    margin-top: -8px;
    left: 0;
    right: 0;

    width: 15px;
    height: 16px;

    background: white;
    border-top: 1px solid #E4E6E8;
    border-left: 1px solid #E4E6E8;

    transform: rotate(45deg);
} */

.simple-popover__tip {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    align-self: flex-start;
    top: 0;
    margin-top: -8px;
    left: 30px;
    right: 0;

    width: 15px;
    height: 16px;

    background: white;
    border-top: 1px solid #E4E6E8;
    border-left: 1px solid #E4E6E8;

    transform: rotate(45deg);
}

.simple-popover__primary-text {
    font-size: 16px;
    font-weight: 400;
    color: #1A232F;
}

.simple-popover__secondary-text {
    margin-top: 18px;

    font-weight: 400;
    font-size: 14px;
    color: #666D74;
}

.popover {
    position: absolute;
    z-index: 99999;
    width: max-content;
    height: max-content;
    /* max-height: 100%; */
    display: none;
}

.popover.up {
    bottom: 100%;
}

.popover.down {
    top: 100%;
}

.popover.left {
    right: 0%;
}

.popover.center {
    bottom: initial;
    top: initial;
    top: 50%;
    transform: translateY(-50%);
    left: calc(100% + 10px);
}

.popover__target {
    z-index: 1330;
    position: relative;
    width: 100%;
    height: 100%;
}

.popover__target:hover + .popover {
    display: block;
}

.material-popover-target {
    z-index: 1330;
    display: inherit;
    align-items: inherit;
    justify-content: inherit;
    position: inherit;
    width: inherit;
    height: inherit;
    text-align: inherit;
}

/* Style injection */

.MuiPopover-root .MuiPaper-root {
    background: none;
}

.MuiPopover-root .MuiPaper-elevation8 {
    box-shadow: none;
}

