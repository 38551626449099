.table__loader {
    width: 100%;
    height: 3px;

    display: flex;
    justify-content: center;
}

.table__loader::before {
    content: "";
    display: block;

    width: 50%;
    height: 3px;
    background-color: #1F9992;

    animation: table__loader--animation 1s ease-out infinite;
}

@keyframes table__loader--animation {
    0% {
        width: 0%;
    }

    50% {
        width: 100%;
    }

    100% {
        width: 0%;
    }
}
