.simple-button {
    height: 14px;
    padding: 0 12px;

    border-radius: 7px;

    font-size: 10px;
    font-weight: 600;

    border: none;
    outline: none;
    background-color: var(--color-orange-climate);
    color: white;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
}
