.alert-settings {
    box-sizing: border-box;

    width: 100%;
    max-width: 800px;
    height: 100%;
    box-sizing: border-box;

    border-radius: 10px;

    font-family: "Montserrat";

    position: relative;
}

.alert-settings * {
    box-sizing: border-box;
    font-size: 14px !important;
}

.alert-settings__container {
    display: flex;
}

.alert-settings__container__navbar {
    z-index: 999;
}

.alert-settings__menu {
    display: flex;
    flex-direction: column;

    width: 100%;
    min-height: 100vh;
    height: max-content;
}

.alert-settings__menu__loader {
    width: 100%;
    height: 4px;

    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    justify-content: center;
}

.alert-settings__menu__loader::before {
    content: "";
    display: block;

    width: 50%;
    height: 4px;
    background-color: var(--color-climate-green);

    animation: alert-settings__menu__loader--animation 1s ease-out infinite;
}

@keyframes alert-settings__menu__loader--animation {
    0% {
        width: 0%;
    }

    50% {
        width: 100%;
    }

    100% {
        width: 0%;
    }
}

.alert-settings__menu-header {
    display: flex;
    margin-top: 50px;
    margin-left: 3%;
    padding-bottom: 10px;
}

.alert-settings__return-icon {
    width: 15px;
    height: 15px;
    color: white;
    background-color: black;
    border-radius: 50%;
}

.alert-settings__header-title {
    margin-top: -2px;
    margin-left: 1%;
    font-size: 24px;
    color: rgba(29, 41, 52, 0.5);
    width: 200px;
}

.alert-settings__user-profile {
    margin-left: 500px;
}

.alert-settings__title {
    display: flex;

    margin-top: 10px;
    margin-left: 6%;

    font-size: 12px;
    font-weight: 600;
    color: #1d2934;
}

.alert-settings__title-dot {
    margin-right: 3px;

    height: 15px;

    text-align: center;
    line-height: 0.3;
    font-size: 38px;
    font-weight: 600;
    color: #1d2934;
}

.alert-settings__text {
    margin-top: 5px;
    margin-left: 8%;

    font-size: 12px;
    font-weight: 500;
    color: #1d2934;
}

.alert-settings__checkbox-container {
    position: relative;
    margin-top: 9px;
    margin-left: 19%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: max-content;
    max-height: 11.5px;
}

.alert-settings__checkbox {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
    cursor: pointer;
}

.alert-settings__checkmark {
    margin-top: 4px;
    position: absolute;
    width: 9px;
    height: 9px;
    background-color: white;
    border: 1px solid #505050;
}

.alert-settings__checkmark::after {
    content: "";
    position: absolute;
    display: none;
}

.alert-settings__checkbox:checked ~ .alert-settings__checkmark {
    margin-top: 4px;
    position: absolute;
    width: 9px;
    height: 9px;
    background-color: #1d2934;
    border: 1px solid #505050;
}

.alert-settings__checkbox:checked ~ .alert-settings__checkmark::after {
    content: "";
    position: absolute;
    display: none;
}

.alert-settings__alerts-menu-container {
    width: 100%;
    height: 100%;
}

.alert-settings__alerts__crop-menu-container {
    padding-left: initial;
    margin: initial;
    width: 100%;

    display: flex;
    gap: 20px;
    overflow-x: scroll;

    list-style: none;
}

.alert-settings__alerts__crop-menu {
    padding: 12px;
    width: max-content;
    cursor: pointer;
    font-weight: 400;
}

.react-tabs__tab--selected .alert-settings__alerts__crop-menu {
    display: flex;

    font-weight: 600;

    background-color: #fff;

    border: 1px solid rgba(202, 205, 208, 0.5);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.react-tabs__tab--selected .alert-settings__alert__crop-menu-icon {
    display: block;
    transform: rotate(90deg);
    margin-left: 8px;
    margin-top: 5px;
    width: 10px;
    height: 10px;
    color: #4e4e4e;
}

.alert-settings__alerts__crop-menu--selected:hover {
    cursor: pointer;
}

.alert-settings__alerts__crop-menu-title {
    font-size: 13px;
}

.alert-settings__alert__crop-menu-icon {
    display: none;
}

.variety-alert__controls {
    column-gap: 10px;

    display: flex;
    flex-flow: row wrap;
}

.variety-alert__controls__download,
.variety-alert__controls__upload {
    padding-top: 10px;
    min-width: 200px;
}

/* TODO: Check why the Tab List creates an extra space above when it has more than 1 variety */
.alert-settings__alerts__variety-container {
    display: flex;
    gap: 10px;
    list-style: none;
    overflow-x: scroll;
    width: 100%;

    margin: 0;
    padding: 0;
    padding-top: 10px;
}

.alert-settings__alerts__variety-button {
    width: max-content;

    color: #000;

    background: none;
    cursor: pointer;
    outline: none;
    border: none;
    border-right: 1px solid rgba(202, 205, 208, 0.5);

    padding: 12px;
}

.react-tabs__tab:last-of-type .alert-settings__alerts__variety-button {
    border-right: none;
}

.react-tabs__tab--selected .alert-settings__alerts__variety-button {
    font-weight: bold;
}

.alert-settings__alerts__variety-title {
    width: max-content;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 400;
}

.react-tabs__tab--selected .alert-settings__alerts__variety-title {
    font-weight: 600;
}

.alert-settings__alerts-container {
    position: relative;
    padding: 20px;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    background-color: #fff;
    border: 1px solid rgba(202, 205, 208, 0.5);
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;

    width: 100%;
    height: max-content;
}

.alert-settings input[type="file"] {
    display: block;
    opacity: 0;
}

@media screen and (max-width: 1100px) {
    .alert-settings {
        margin: 0 auto;
        margin-top: 20px;
    }

    .variety-alert__controls {
        display: none;
    }
}
@media screen and (max-width: 600px) {
    .alert-settings {
        margin-top: initial;
        padding: 20px 0px;
    }

    .alert-settings__alerts-container {
        padding: 0px;
    }

    .alert-settings__alerts__crop-menu-container,
    .alert-settings__alerts__variety-container {
        padding-left: 20px;
    }
}
