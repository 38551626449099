.color-picker {
    flex: 1;

    row-gap: 10px;
    grid-template-columns: repeat(5, 1fr);
}

.color-picker__row {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
}

.color-picker__item {
    display: block;

    outline: none;
    background: none;
    border: none;
    cursor: pointer;

    width: 20px;
    height: 20px;
    border-radius: 4px;
    padding: 3px;

    transition: all 0.2s ease-out;

    justify-self: center;
}

.color-picker__item:hover {
    transform: scale(1.1);
}
